<template>
  <Drawer :drawer="drawer" title="查看费率" :size="830" @close="close">
    <div class="view">
      <div v-for="(order, index) in orderFee" :key="index" class="panel">
        <div class="header">
          <span style="margin-left:20px;">{{ order.lable }}</span>
          <div v-show="!stretchArr[index]" class="btn" @click="changeStretch(index)">
            <img src="@/assets/images/order/zhankai.png" />
            展开
          </div>
          <div v-show="stretchArr[index]" class="btn" @click="changeStretch(index)">
            <img src="@/assets/images/order/shouqi.png" />
            收起
          </div>
        </div>
        <div v-show="stretchArr[index]" class="showList">
          <div class="content">
            <div
              v-for="(item, $index) in order.dataItems"
              :key="$index"
              class="sentType"
              :class="[{ active: activeArr[index] === $index }]"
            >
              <div class="chooseItem">
                <img src="@/assets/images/gou.png" width="16" height="14" />
              </div>
              <div v-if="item.remoteType" class="remoteType flex_c_c">
                {{ item.remoteType === 1 ? '偏远' : '超偏远' }}
              </div>
              <div class="form">
                <img
                  style="margin-bottom: 12px;margin-top: 15px;"
                  :src="require(`@/assets/images/serverSupply/${item.express.toLowerCase()}.png`)"
                  width="100"
                  height="33"
                />
                <div class="item">
                  <p>服务类型</p>
                  <p class="blue">{{ item.service }}</p>
                </div>
                <div class="item">
                  <p>件数</p>
                  <p class="blue">{{ item.qty }}</p>
                </div>
                <div class="item">
                  <p>计费重量</p>
                  <p class="blue">{{ item.weightKG }} KG</p>
                </div>
                <div class="item">
                  <p>预估费用</p>
                  <p class="red">
                    {{ item.currency | formatterCurrency }} {{ item.estimateAmount }}
                    <el-popover placement="bottom" trigger="hover">
                      <ul>
                        <li
                          v-for="(a, i) in item.rateItems"
                          :key="i"
                          class="detail-item"
                        >
                          <span style="margin-left: 16px">{{ a.name }}：</span>
                          <span
                            style="margin-right: 16px"
                            class="detail-money"
                          >{{ item.currency | formatterCurrency }} {{ a.surcharge.amount }}</span
                          >
                        </li>
                      </ul>
                      <span slot="reference" class="tips">
                        <svg-icon iconClass="detail" className="icon" />明细</span
                      >
                    </el-popover>
                  </p>
                </div>
                <div class="item">
                  <p>RMB费用</p>
                  <p class="red">¥ {{ item.estimateAmountRMB }}</p>
                </div>
                <div class="item">
                  <p>RMB费用/KG</p>
                  <p class="red">¥ {{ item.perKgEstimateAmountRMB }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-show="!stretchArr[index]" class="hideList">
          共 {{ order.dataItems.length }} 个服务
        </div>
      </div>
    </div>
  </Drawer>
</template>

<script>
const Drawer = () => import('@/components/drawer/Drawer')
import { getEstimateRate } from '@/api/fee'
export default {
  components: { Drawer },
  filters: {
    formatterCurrency(val) {
      const obj = {
        'USD': '$',
        'RMB': '¥'
      }
      return obj[val]
    }
  },
  props: {
    drawer: {
      type: Boolean,
      default: false
    },
    orderId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      height: 0,
      orderFee: [],
      activeArr: [],
      stretchArr: []
    }
  },
  watch: {
    drawer(val) {
      if (val) {
        this.getList()
      }
    },
    orderFee: {
      handler: function(data) {
        const arr = []
        data.forEach(item => {
          const num = item.dataItems.findIndex((a) => a.isDefault === true) || 0
          arr.push(num)
        })
        this.activeArr = arr
      },
      deep: true
    }
  },
  methods: {
    async getList() {
      try {
        const res = await getEstimateRate({
          orderId: this.orderId
        })
        if (res.success) {
          const arr = this.formaterData(res.data)
          console.log('arr', arr)
          const stretchArr = arr.map(() => {
            return 1
            // if ($index === 0) {
            //   return 1 // 1 展开状态 0 收起状态
            // } else {
            //   return 0
            // }
          })
          this.orderFee = arr
          this.stretchArr = stretchArr
        } else {
          this.$message.error(res.errorMessage)
        }
        console.log(res)
      } catch (error) {
        console.error(error)
      }
    },
    close() {
      this.$emit('close', false)
    },
    formaterData(arr) {
      const feeArr = []
      arr.forEach((item) => {
        if (item.isVisible) {
          const flag = feeArr.find((a) => a.lable === item.express)
          if (flag) {
            flag.dataItems.push(item)
          } else {
            feeArr.push({
              lable: item.express,
              dataItems: [item]
            })
          }
        }
      })
      return feeArr
    },
    changeStretch(index) {
      this.stretchArr.splice(index, 1, !this.stretchArr[index])
    }
  }
}
</script>

<style lang="less" scoped>
.view {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: 20px 0;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  .data {
    width: calc(100% - 80px);
    margin: 24px auto;
    height: calc(100% - 72px);
  }
  .panel {
    position: relative;
    width: 790px;
    height: auto;
    min-height: 60px;
    background: #F7F7F7;
    border-radius: 6px 6px 6px 6px;
    margin-left: 20px;
    margin-bottom: 15px;
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 60px;
    font-weight: 400;
    color: #333333;
    line-height: 32px;
    span {
      font-size: 16px;
    }
  }
  .btn {
    width: 80px;
    height: 30px;
    background: #FFFFFF;
    border-radius: 4px 4px 4px 4px;
    text-align: center;
    margin-right: 20px;
    cursor: pointer;
    img {
      margin: 6px 0;
    }
  }
  .hideList {
    position: absolute;
    top: 21px;
    height: 19px;
    font-size: 14px;
    line-height: 19px;
    font-weight: 400;
    color: #2395F3;
    text-indent: 100px;
  }
  .content {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  .sentType {
    width: 240px;
    height: 260px;
    background: #ffffff;
    border-radius: 4px;
    opacity: 1;
    position: relative;
    cursor: pointer;
    margin-bottom: 20px;
    box-sizing: border-box;
    & + .sentType {
      margin-left: 20px;
    }
    .chooseItem {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 16px;
      height: 16px;
      background: #EEEEEE;
      border-radius: 4px 4px 4px 4px;
      position: absolute;
      top: 15px;
      right: 15px;
      z-index: 1;
      img {
        width: 10px;
        height: 8px;
      }
    }
    .remoteType {
      position: absolute;
      top: 0;
      left: 0;
      width: auto;
      height: 24px;
      background: rgba(156, 103, 254, .1);
      border-radius: 4px 0px 4px 0px;
      font-size: 12px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #9D67FE;
      padding: 0 8px;
      box-sizing: border-box;
    }
  }
  .active {
    .chooseItem {
      background: #2395F3;
    }
  }
  .form {
    padding: 15px;
    .item {
      width: 100%;
      display: flex;
      justify-content: space-between;
      height: 19px;
      margin-bottom: 10px;
      line-height: 24px;
      font-size: 14px;
      font-weight: 400;
      color: #333333;
    }
    p {
      display: flex;
      align-items: center;
    }
    .blue {
      font-weight: bold;
      color: #2395f3;
    }
    .red {
      font-weight: bold;
      color: #fe453d;
    }
    .tips {
      display: flex;
      align-items: center;
      background: rgba(157, 103, 254, 0.1);
      padding: 2px 6px;
      color: #9d67fe;
      font-size: 12px;
      font-weight: 400;
      border-radius: 4px;
      box-sizing: border-box;
      margin-left: 4px;
    }
    .icon {
      font-size: 20px;
      vertical-align: text-bottom;
    }
  }
}
</style>
