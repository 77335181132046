<template>
  <Drawer :drawer="drawer" title="订单详情" :size="1160" @close="close">
    <div class="view">
      <div class="panle-container">
        <div class="panle-title">基本信息</div>
        <el-row>
          <el-col :span="8">
            <div class="field">
              <label>订单编号</label>
              <p>{{ order.id }}</p>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="field">
              <label>客户单号</label>
              <p style="color: #2395f3" class="orderNo">
                <span class="copy">{{ order.referenceNumber }}</span>
                <img src="@/assets/images/order/copy2.png" title="复制" @click="handleCopy(order.referenceNumber)" />
              </p>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="field">
              <label>追踪单号</label>
              <p style="color: #2395f3" class="orderNo">
                <span class="copy">{{ order.trackingNumber }}</span>
                <img src="@/assets/images/order/copy2.png" title="复制" @click="handleCopy(order.trackingNumber)" />
              </p>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <div class="field">
              <label>件数</label>
              <p>{{ order.qty }}</p>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="field">
              <label>状态</label>
              <p class="status">{{ order.status | formatStatus }}</p>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="field">
              <label>实重</label>
              <p>{{ order.actWeightKG }} KG</p>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <div class="field">
              <label>材积重</label>
              <p>{{ order.volumeWeightKG }} KG</p>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="field">
              <label>计费重</label>
              <p>{{ order.weightKG }} KG</p>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="field">
              <label>费用</label>
              <p>{{ order.estimateAmount }} {{ order.currency }}</p>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <div class="field">
              <label>国家</label>
              <p>{{ formatterCountryCode(order.originCountryCode) }}</p>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="field">
              <label>派送方式</label>
              <p style="color: #2395f3;font-weight: bold;">{{ order.expressSupplier }} {{ order.expressService }}</p>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="field">
              <label>创建时间</label>
              <p>{{ order.createdOn ? order.createdOn.slice(0, order.createdOn.length - 3) : '' }}</p>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <div class="field">
              <label>上传时间</label>
              <p>{{ order.uploadOn ? order.uploadOn.slice(0, order.uploadOn.length - 3) : '' }}</p>
            </div>
          </el-col>
          <el-col v-if="order.fbaId || order.fbaRefNo" :span="8">
            <div class="field">
              <label>FBA信息</label>
              <p>
                <span v-if="order.fbaId">{{ order.fbaId }}</span>
                <span v-if="order.fbaId && order.fbaRefNo" style="margin: 0 10px;">|</span>
                <span v-if="order.fbaRefNo">{{ order.fbaRefNo }}</span>
              </p>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="field">
              <label>备注</label>
              <p>
                <el-popover
                  placement="bottom-end"
                  width="440"
                  trigger="hover">
                  <div class="remarks-box">
                    <div class="remarks-header">
                      <span>备注内容</span>
                      <span @click="handleCopy(order.remarks)"><img src="@/assets/images/order/copy3.png">复制</span>
                    </div>
                    <div class="remarks-body">
                      {{ order.remarks }}
                    </div>
                  </div>
                  <img slot="reference" src="@/assets/images/const/message.png">
                </el-popover>
              </p>
            </div>
          </el-col>
        </el-row>
        <el-row v-if="order.deliveryDemand">
          <div class="field">
            <label>送仓要求</label>
            <p>{{ order.deliveryDemand }}</p>
          </div>
        </el-row>
      </div>
      <div class="panle-container">
        <div class="type" :style="{width: quotesType === 1 ? '240px' : '120px'}">
          <div
            class="btn"
            :class="{ active: addressActive === 0 }"
            :style="{width: quotesType === 1 ? '50%' : '100%'}"
            @click="addressActive = 0"
          >
            收件方信息
          </div>
          <div
            v-if="quotesType === 1"
            class="btn"
            :class="{ active: addressActive === 1 }"
            @click="addressActive = 1"
          >
            进口商信息
          </div>
        </div>
        <el-row>
          <el-col :span="12">
            <div class="field">
              <label>公司名称</label>
              <p>{{ address.companyName }}</p>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="field">
              <label>收件人</label>
              <p>{{ address.attentionName }}</p>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col v-if="quotesType && addressActive" :span="6">
            <div class="field">
              <label>税号</label>
              <p>{{ address.taxNumber }}</p>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="field">
              <label>国家</label>
              <p>{{ formatterCountryCode(address.countryCode) }}</p>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="field">
              <label>省州</label>
              <p>{{ address.state }}</p>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="field">
              <label>城市</label>
              <p>{{ address.city }}</p>
            </div>
          </el-col>
          <el-col v-if="!addressActive" :span="6">
            <div class="field">
              <label>邮编</label>
              <p>{{ address.postalCode }}</p>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col v-if="addressActive" :span="6">
            <div class="field">
              <label>邮编</label>
              <p>{{ address.postalCode }}</p>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="field">
              <label>电话</label>
              <p>{{ address.phone }}</p>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="field">
              <label>邮箱</label>
              <p>{{ address.email }}</p>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <div class="field">
              <label>详细地址一</label>
              <p>
                <span class="label flex_c_c">{{ address.classificationName }}</span>
                {{ address.addressLine1 }}
              </p>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <div class="field">
              <label>详细地址二</label>
              <p>{{ address.addressLine2 }}</p>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="type" :style="{width: quotesType === 1 ? '240px' : '120px'}" style="margin-left: 35px;">
        <div
          class="btn"
          :style="{width: quotesType === 1 ? '50%' : '100%'}"
          :class="{ active: active === 0 }"
          @click="changeType(0)"
        >
          包裹信息
        </div>
        <div
          v-if="quotesType === 1"
          class="btn"
          :class="{ active: active === 1 }"
          @click="changeType(1)"
        >
          产品信息
        </div>
      </div>
      <div class="data">
        <BaseTable
          v-show="active"
          height="320"
          :tableData="tableData2"
          :pageIndex="pager.pageIndex"
          :pageSize="pager.pageSize"
          :loading="loading"
          :isPager="true"
          @handlePageChange="handlePageChange"
        ></BaseTable>
        <BaseTable
          v-show="!active"
          height="320"
          :tableData="tableData1"
          :pageIndex="pager.pageIndex"
          :pageSize="pager.pageSize"
          :loading="loading"
          :isPager="true"
          @handlePageChange="handlePageChange"
          @sortChange="sortChange"
        >
          <template slot="productURL" slot-scope="row">
            <i class="el-icon-link" @click="openUrl(row.scope.productURL)" />
          </template>
        </BaseTable>
      </div>
    </div>
  </Drawer>
</template>

<script>
const Drawer = () => import('@/components/drawer/Drawer')
const BaseTable = () => import('@/components/table/BaseTable')
import {
  getOrderDetail,
  getOrderDimensionPageList,
  getOrderProductPageList
} from '@/api/fee'
import config from '@/utils/config'
import { mapGetters } from 'vuex'

export default {
  components: { Drawer, BaseTable },
  filters: {
    formatStatus: function(value) {
      if (value || value === 0) {
        const item = config.orderStatusOptions.find(
          (a) => parseInt(a.value, 10) === value
        )
        return item.label
      } else {
        return ''
      }
    }
  },
  props: {
    drawer: {
      type: Boolean,
      default: false
    },
    orderId: {
      type: String,
      default: ''
    },
    quotesType: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      active: 0,
      order: {
        address: {}
      },
      tableData1: {
        fileds: [
          {
            filed: 'packageNumber',
            label: '包裹编号',
            width: '100',
            isSort: true
          },
          {
            filed: 'trackingNumber',
            label: '追踪单号',
            width: '180',
            isCopy: true
          },
          {
            filed: 'labelRefNo',
            label: '箱号',
            width: '120',
            isCopy: true
          },
          {
            filed: 'agentLength',
            label: '长',
            width: ''
          },
          {
            filed: 'agentWidth',
            label: '宽',
            width: ''
          },
          {
            filed: 'agentHeight',
            label: '高',
            width: ''
          },
          {
            filed: 'agentDimensionUnit',
            label: '尺寸单位',
            width: ''
          },
          {
            filed: 'agentWeight',
            label: '实重',
            width: ''
          },
          {
            filed: 'calculateVolumeWeight',
            label: '材积重',
            width: ''
          },
          {
            filed: 'calculateWeight',
            label: '计费重量',
            width: '',
            formatter: (row) =>
              row.calculateWeight ? row.calculateWeight.toFixed(2) : '0'
          },
          {
            filed: 'agentWeightUnit',
            label: '重量单位',
            width: ''
          }
        ],
        columns: [],
        total: 0
      },
      tableData2: {
        fileds: [
          {
            filed: 'nameEN',
            label: '英文品名',
            width: '140'
          },
          {
            filed: 'nameCH',
            label: '中文品名',
            width: '120'
          },
          {
            filed: 'hsCode',
            label: 'HS CODE',
            width: '100'
          },
          {
            filed: 'qty',
            label: '数量',
            width: ''
          },
          {
            filed: 'unitPrice',
            label: '产品价值',
            width: ''
          },
          {
            filed: 'agentWeightUnit',
            label: '币制',
            width: ''
          },
          {
            filed: 'currency',
            label: '产品重量',
            width: ''
          },
          {
            filed: 'weightUnit',
            label: '重量单位',
            width: ''
          },
          {
            filed: 'sku',
            label: 'SKU',
            width: ''
          },
          {
            filed: 'material',
            label: '材质',
            width: ''
          },
          {
            filed: 'usedFor',
            label: '用途',
            width: ''
          },
          {
            filed: 'productURL',
            label: '产品链接',
            width: '',
            isCustom: true
          },
          {
            filed: 'packageUnit',
            label: '计费单位',
            width: ''
          }
        ],
        columns: [],
        total: 0
      },
      pager: {
        pageIndex: 1,
        pageSize: 20
      },
      loading: false,
      addressActive: 0,
      sortable: {
        sortField: undefined,
        sortBy: undefined
      }
    }
  },
  computed: {
    ...mapGetters(['addressDropOption']),
    address: function() {
      if (this.addressActive) {
        return {
          ...this.order.importerAddress
        }
      } else {
        return {
          ...this.order.address
        }
      }
    }
  },
  watch: {
    drawer(val) {
      if (val) {
        this.getOrder()
        this.pager = {
          pageIndex: 1,
          pageSize: 20
        }
        this.getPackageList({
          pageIndex: 1,
          pageSize: 20
        })
      }
    }
  },
  mounted() {
    if (!this.addressDropOption || this.addressDropOption.length === 0) {
      this.$store.dispatch('getAddress')
    }
  },
  methods: {
    async getOrder() {
      try {
        const res = await getOrderDetail({
          orderId: this.orderId
        })
        if (res.success) {
          this.order = res.data
        } else {
          this.$message.error(res.errorMessage)
        }
        console.log(res)
      } catch (error) {
        console.error(error)
      }
    },
    async getPackageList(params) {
      try {
        params = {
            ...params,
            queryParam: {
                 orderId: this.orderId,
                 ...this.sortable
            }
        }
        const res = await getOrderDimensionPageList(params)
        if (res.success) {
          this.tableData1.columns = res.data.list
          this.tableData1.total = res.data.total
        } else {
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        console.error(error)
      }
    },
    async getProductList(params) {
      try {
        params = {
            ...params,
            queryParam: {
                 orderId: this.orderId
            }
        }
        const res = await getOrderProductPageList(params)
        if (res.success) {
          this.tableData2.columns = res.data.list
          this.tableData2.total = res.data.total
        } else {
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        console.error(error)
      }
    },
    close() {
      this.active = 0
      this.addressActive = 0
      this.$emit('close')
    },
    changeType(type) {
      this.active = type
      this.pager = {
        pageIndex: 1,
        pageSize: 20
      }
      if (type) {
        this.getProductList({
          pageIndex: 1,
          pageSize: 20
        })
      } else {
        this.getPackageList({
          pageIndex: 1,
          pageSize: 20
        })
      }
    },
    formatterCountryCode(code) {
      if (this.addressDropOption && code) {
        const country = this.addressDropOption.find((a) => a.code === code)
        if (country) {
          return `${country.code}-${country.label}`
        } else {
          return ''
        }
      } else {
        return ''
      }
    },
    handlePageChange(pager) {
      this.pager = pager
      if (this.active) {
        this.getProductList(pager)
      } else {
        this.getPackageList(pager)
      }
    },
    sortChange(orderObj) {
      if (orderObj.order) {
        this.sortable = {
          sortField: orderObj.prop,
          sortBy: orderObj.order === 'asc' ? 0 : 1
        }
      } else {
        this.sortable = {}
      }
      this.getPackageList(this.pager)
    },
    // 提示内容复制
    handleCopy(val) {
      const url = val
      const oInput = document.createElement('input')
      oInput.value = url
      document.body.appendChild(oInput)
      oInput.select() // 选择对象;

      document.execCommand('Copy') // 执行浏览器复制命令
      this.$message({
        message: '复制成功',
        type: 'success'
      })
      oInput.remove()
    }
  }
}
</script>

<style lang="less" scoped>
.view {
  width: 100%;
  height: 100%;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  padding-top: 20px;
  box-sizing: border-box;
  .panel {
    margin-top: 28px;
  }
  .panle-container {
    width: calc(100% - 40px);
    height: auto;
    padding: 20px 15px;
    border-radius: 6px 6px 6px 6px;
    background: #FBFBFB;
    margin: 0 auto 20px;
    box-sizing: border-box;
    .panle-title {
      height: 21px;
      font-size: 16px;
      font-weight: 400;
      color: #2395F3;
      line-height: 21px;
      margin-left: 10px;
      margin-bottom: 20px;
    }
    .field {
      width: 100%;
      height: 30px;
      display: flex;
    }
    label {
      display: inline-block;
      width: 100px;
      height: 30px;
      background: #FFFFFF;
      border-radius: 4px;
      font-size: 14px;
      color: #999999;
      line-height: 30px;
      text-align: center;
    }
    p {
      display: flex;
      align-items: center;
      width: auto;
      height: 30px;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      margin-left: 16px;
      img {
        margin: 5px 0;
        cursor: pointer;
      }
      .copy {
        display: inline-block;
        max-width: 170px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
      .label {
        width: 40px;
        height: 24px;
        background: rgba(35, 149, 243, .1);
        border-radius: 4px 4px 4px 4px;
        font-size: 12px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #2395F3;
        margin-right: 5px;
      }
    }
    .status {
      font-size: 12px;
      color: #35ba83;
      background-color: rgba(53, 186, 131, 0.1);
      padding: 0 11px;
      border-radius: 4px 4px 4px 4px;
      box-sizing: border-box;
    }
  }
  &:deep(.el-row + .el-row) {
    margin-top: 8px;
  }
  .type {
    display: flex;
    height: 30px;
    border-radius: 4px;
    border: 1px solid #2395f3;
    background: #ffffff;
    margin-bottom: 15px;
    box-sizing: border-box;
    .btn {
      width: 50%;
      font-size: 14px;
      font-weight: 400;
      line-height: 30px;
      text-align: center;
      color: #2395f3;
      cursor: pointer;
    }
    .active {
      background: #2395f3;
      color: #ffffff;
    }
  }
  .data {
    width: calc(100% - 40px);
    height: 400px;
    margin: 20px auto 0;
  }
}
.log-body {
  width: calc(100% - 40px);
  height: 100%;
  margin: 0 20px;
  .track-num {
    width: 480px;
    height: 60px;
    line-height: 60px;
    text-align: center;
    background: #F7F7F7;
    border-radius: 6px 6px 6px 6px;
    font-size: 16px;
    font-weight: 400;
    .number {
      color: #2395F3;
    }
  }
  .track-other {
    display: flex;
    width: 480px;
    height: 80px;
    margin-top: 10px;
    .country {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 180px;
      height: 100%;
      background: #F7F7F7;
      border-radius: 6px 6px 6px 6px;
      opacity: 1;
      margin-right: 10px;
      font-size: 18px;
      font-weight: bold;
      color: #2395F3;
      img {
        margin: 0 24px;
      }
    }
    .navication {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 290px;
      height: 100%;
      background: #F7F7F7;
      border-radius: 6px 6px 6px 6px;
      opacity: 1;
      .nav-step-text {
        font-size: 14px;
        font-weight: 400;
        color: #333333;
      }
      .nav-step-jd {
        display: flex;
        align-items: center;
        margin-top: 8px;
        .nav-setp-dot {
          width: 8px;
          height: 8px;
          background: #CDCDCD;
          border-radius: 50px;
          opacity: 1;
        }
        .nav-dot-blue {
          background: #2395F3;
        }
        .nav-dot-red {
          background: red;
        }
        .nav-setp-line {
          width: 42px;
          border-bottom: 1px solid #CDCDCD;
          margin: 0 16px;
        }
        .nav-line-blue {
          border-bottom: 1px solid #2395F3;
        }
      }
    }
  }
  .track-step {
    width: 480px;
    height: auto;
    min-height: 438px;
    background: #FBFBFB;
    border-radius: 6px 6px 6px 6px;
    opacity: 1;
    margin-top: 20px;
    padding: 22px 0 0 20px;
    box-sizing: border-box;
    .step-item {
      position: relative;
      height: auto;
      color: #999999;
      .step-title {
        display: flex;
        align-items: center;
        position: relative;
        height: 16px;
        .dot {
          display: flex;
          justify-content: center;
          width: 12px;
        }
        .step-dot {
          width: 8px;
          height: 8px;
          background: #CDCDCD;
          border-radius: 50px;
        }
        .blue-dot {
          width: 12px;
          height: 12px;
          background: #2395F3;
        }
        .step-time {
          position: absolute;
          left: 26px;
          width: calc(100% - 55px);
          font-size: 12px;
          font-weight: 400;
          overflow:hidden;
          text-overflow:ellipsis;
          white-space:nowrap;
        }
      }
      .step-info {
        font-size: 14px;
        font-weight: bold;
        color: #999999;
        line-height: 19px;
        margin: 8px 0 0 26px;
        padding-bottom: 24px;
        box-sizing: border-box;
      }
      .step-line {
        position: absolute;
        top: 10px;
        left: 6px;
        height: 100%;
        border-left: 1px solid #CDCDCD;
      }
      .blue {
        color: #2395F3;
      }
      .blue-line {
        border-left: 1px solid #2395F3;
      }
    }
  }
}
/deep/ .el-drawer__body {
  overflow: auto;
}
</style>
<style lang="less">
.remarks-box {
  width: 100%;
  height: 240px;
  border-radius: 4px 4px 4px 4px;
  border: 1px solid #EEEEEE;
  box-sizing: border-box;
  overflow: hidden;
  .remarks-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 40px;
    background: #F7F7F7;
    border-bottom: 1px solid #EEEEEE;
    box-sizing: border-box;
    padding: 0 20px;
    font-size: 12px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    & span:nth-child(2) {
      color: #2395F3;
      cursor: pointer;
    }
  }
  .remarks-body {
    width: 100%;
    height: calc(100% - 40px);
    padding: 15px 20px;
    box-sizing: border-box;
    font-size: 12px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    overflow-y: auto;
  }
}
</style>
