var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "contents" },
    [
      _c("div", { staticClass: "header" }, [
        _c(
          "div",
          { staticClass: "tab" },
          _vm._l(_vm.statusOptions, function (item, $index) {
            return _c(
              "span",
              {
                directives: [
                  {
                    name: "permissson",
                    rawName: "v-permissson",
                    value: `${item.permissson}`,
                    expression: "`${item.permissson}`",
                  },
                ],
                key: $index,
                class: ["tabItem", { active: _vm.activeName === item.value }],
                on: {
                  click: function ($event) {
                    return _vm.handleClick(item.value)
                  },
                },
              },
              [
                _vm._v(
                  _vm._s(
                    item.value === 10000
                      ? `${item.label}(${_vm.tabTotal})`
                      : `${item.label}(${
                          _vm.tabNumer?.find((a) => a.type === +item.value)
                            ?.qty || 0
                        })`
                  )
                ),
              ]
            )
          }),
          0
        ),
        _c(
          "div",
          { staticClass: "opt" },
          [
            _vm.filterList.date
              ? _c("div", { staticClass: "checkDate flex_c_c" }, [
                  _vm._v(
                    " 查询时间：" +
                      _vm._s(
                        _vm.filterList.date[0] + "~" + _vm.filterList.date[1]
                      ) +
                      " "
                  ),
                ])
              : _vm._e(),
            _c(
              "div",
              { staticClass: "search" },
              [
                _c(
                  "el-popover",
                  {
                    ref: "popover",
                    staticClass: "searchPop",
                    attrs: {
                      placement: "top",
                      title: "批量搜索",
                      trigger: "click",
                      width: "378",
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "close",
                        on: { click: _vm.closeSearchPanel },
                      },
                      [_c("i", { staticClass: "el-icon-close" })]
                    ),
                    _c("base-muti-input", {
                      ref: "baseMutiInput",
                      attrs: { default: _vm.referenceNumber },
                      model: {
                        value: _vm.referenceNumber,
                        callback: function ($$v) {
                          _vm.referenceNumber = $$v
                        },
                        expression: "referenceNumber",
                      },
                    }),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "text-align": "right",
                          margin: "16px 0 0",
                        },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "small" },
                            on: { click: _vm.handleEmpty },
                          },
                          [_vm._v("清空")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "small", type: "primary" },
                            on: { click: _vm.handleConfirm },
                          },
                          [_vm._v("确定")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("el-input", {
                  staticClass: "input-search",
                  attrs: {
                    value: _vm.searchVal,
                    placeholder: "请输入单号搜索",
                  },
                  on: { focus: _vm.handleFocus },
                }),
                _c(
                  "div",
                  {
                    staticClass: "btn-search",
                    on: { click: _vm.handleConfirm },
                  },
                  [_c("i", { staticClass: "el-icon-search" })]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "import", on: { click: _vm.batchExport } },
              [
                _c("img", {
                  attrs: {
                    src: require("@/assets/images/bill/import.png"),
                    alt: "",
                  },
                }),
                _vm._v("导出 "),
              ]
            ),
            _vm._l(_vm.customList, function (item) {
              return [
                [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.activeName === item.index,
                          expression: "(activeName === item.index)",
                        },
                        {
                          name: "permissson",
                          rawName: "v-permissson",
                          value: `${item.permissson}`,
                          expression: "`${item.permissson}`",
                        },
                      ],
                      key: `${item.permissson}`,
                      staticClass: "customer-list",
                      on: { click: _vm.customerList },
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/images/order/setting.png"),
                        },
                      }),
                      _vm._v(" 自定义列表 "),
                    ]
                  ),
                ],
              ]
            }),
            _c(
              "div",
              { staticClass: "filter" },
              [
                _c(
                  "base-filter",
                  {
                    ref: "BaseFilter",
                    attrs: { width: 864, isActive: _vm.isActive },
                    on: { sendFilter: _vm.sendFilter, reset: _vm.reset },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "filter" },
                      [
                        _c(
                          "el-form",
                          {
                            ref: "form",
                            attrs: {
                              "label-position": "right",
                              model: _vm.filterList,
                              "label-width": "70px",
                            },
                          },
                          [
                            _c(
                              "el-row",
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "创建日期" } },
                                      [
                                        _c("el-date-picker", {
                                          staticStyle: { width: "320px" },
                                          attrs: {
                                            type: "daterange",
                                            "range-separator": "-",
                                            "start-placeholder": "开始日期",
                                            "end-placeholder": "结束日期",
                                            "value-format": "yyyy-MM-dd",
                                            "unlink-panels": "",
                                            "picker-options": _vm.pickerOptions,
                                          },
                                          model: {
                                            value: _vm.filterList.date,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.filterList,
                                                "date",
                                                $$v
                                              )
                                            },
                                            expression: "filterList.date",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "省州" } },
                                      [
                                        _c("el-input", {
                                          staticStyle: { width: "320px" },
                                          attrs: { placeholder: "请输入省州" },
                                          model: {
                                            value: _vm.filterList.state,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.filterList,
                                                "state",
                                                $$v
                                              )
                                            },
                                            expression: "filterList.state",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-row",
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "始发地" } },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            staticStyle: { width: "320px" },
                                            attrs: {
                                              placeholder: "请选择始发地",
                                            },
                                            model: {
                                              value:
                                                _vm.filterList.originCityId,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.filterList,
                                                  "originCityId",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "filterList.originCityId",
                                            },
                                          },
                                          _vm._l(
                                            _vm.originCountryOptions,
                                            function (item) {
                                              return _c("el-option", {
                                                key: item.value,
                                                attrs: {
                                                  label: item.label,
                                                  value: item.value,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "邮编" } },
                                      [
                                        _c("el-input", {
                                          staticStyle: { width: "320px" },
                                          attrs: { placeholder: "请输入邮编" },
                                          model: {
                                            value: _vm.filterList.postalCode,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.filterList,
                                                "postalCode",
                                                $$v
                                              )
                                            },
                                            expression: "filterList.postalCode",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-row",
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c(
                                      "el-row",
                                      [
                                        _c(
                                          "el-col",
                                          { attrs: { span: 24 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              { attrs: { label: "国家" } },
                                              [
                                                _c(
                                                  "el-select",
                                                  {
                                                    staticStyle: {
                                                      width: "320px",
                                                    },
                                                    attrs: {
                                                      multiple: "",
                                                      placeholder: "请选择国家",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.filterList
                                                          .countryCode,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.filterList,
                                                          "countryCode",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "filterList.countryCode",
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.addressDropCodeOption,
                                                    function (item) {
                                                      return _c("el-option", {
                                                        key: item.value,
                                                        attrs: {
                                                          label: item.label,
                                                          value: item.value,
                                                        },
                                                      })
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-row",
                                      [
                                        _c(
                                          "el-col",
                                          { attrs: { span: 24 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              { attrs: { label: "派送方式" } },
                                              [
                                                _c(
                                                  "el-select",
                                                  {
                                                    staticStyle: {
                                                      width: "320px",
                                                    },
                                                    attrs: {
                                                      placeholder:
                                                        "请选择派送方式",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.filterList
                                                          .expressSupplier,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.filterList,
                                                          "expressSupplier",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "filterList.expressSupplier",
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.esIdOptions,
                                                    function (item) {
                                                      return _c("el-option", {
                                                        key: item.value,
                                                        attrs: {
                                                          label: item.label,
                                                          value: item.value,
                                                        },
                                                      })
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-row",
                                      [
                                        _c(
                                          "el-col",
                                          { attrs: { span: 24 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              { attrs: { label: "服务类型" } },
                                              [
                                                _c(
                                                  "el-select",
                                                  {
                                                    staticStyle: {
                                                      width: "320px",
                                                    },
                                                    attrs: {
                                                      placeholder:
                                                        "请选择服务类型",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.filterList
                                                          .expressService,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.filterList,
                                                          "expressService",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "filterList.expressService",
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.esServiceIdOptions,
                                                    function (item) {
                                                      return _c("el-option", {
                                                        key: item.value,
                                                        attrs: {
                                                          label: item.label,
                                                          value: item.value,
                                                        },
                                                      })
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm.activeName === 10000
                                      ? _c(
                                          "el-row",
                                          [
                                            _c(
                                              "el-col",
                                              { attrs: { span: 24 } },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  { attrs: { label: "状态" } },
                                                  [
                                                    _c(
                                                      "el-select",
                                                      {
                                                        staticStyle: {
                                                          width: "320px",
                                                        },
                                                        attrs: {
                                                          multiple: "",
                                                          placeholder:
                                                            "请选择状态",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.filterList
                                                              .status,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.filterList,
                                                              "status",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "filterList.status",
                                                        },
                                                      },
                                                      _vm._l(
                                                        _vm.config.orderStatusOptions.slice(
                                                          1
                                                        ),
                                                        function (item) {
                                                          return _c(
                                                            "el-option",
                                                            {
                                                              key: item.value,
                                                              attrs: {
                                                                label:
                                                                  item.label,
                                                                value:
                                                                  item.value,
                                                              },
                                                            }
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c(
                                      "el-row",
                                      [
                                        _c(
                                          "el-col",
                                          { attrs: { span: 24 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              { attrs: { label: "业务员" } },
                                              [
                                                _c(
                                                  "el-select",
                                                  {
                                                    staticStyle: {
                                                      width: "320px",
                                                    },
                                                    attrs: {
                                                      filterable: "",
                                                      placeholder: "请选择",
                                                      multiple: "",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.filterList.saleIds,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.filterList,
                                                          "saleIds",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "filterList.saleIds",
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.userOptions,
                                                    function (item) {
                                                      return _c("el-option", {
                                                        key: item.value,
                                                        attrs: {
                                                          label: item.label,
                                                          value: item.value,
                                                        },
                                                      })
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-row",
                                      [
                                        _c(
                                          "el-col",
                                          { attrs: { span: 24 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              { attrs: { label: "客户" } },
                                              [
                                                _c(
                                                  "el-select",
                                                  {
                                                    staticStyle: {
                                                      width: "320px",
                                                    },
                                                    attrs: {
                                                      filterable: "",
                                                      placeholder: "请选择客户",
                                                      multiple: "",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.filterList
                                                          .companyIds,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.filterList,
                                                          "companyIds",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "filterList.companyIds",
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.companyDrapdown,
                                                    function (item) {
                                                      return _c("el-option", {
                                                        key: item.value,
                                                        attrs: {
                                                          label: item.label,
                                                          value: item.value,
                                                        },
                                                      })
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-row",
                                      [
                                        _c(
                                          "el-col",
                                          { attrs: { span: 24 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              { attrs: { label: "账号搜索" } },
                                              [
                                                _c("el-input", {
                                                  staticStyle: {
                                                    width: "320px",
                                                  },
                                                  attrs: {
                                                    placeholder: "请输入",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.filterList
                                                        .accountNumber,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.filterList,
                                                        "accountNumber",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "filterList.accountNumber",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
          ],
          2
        ),
      ]),
      _c(
        "div",
        { staticClass: "data" },
        [
          _vm.height
            ? _c("BaseTable", {
                attrs: {
                  loading: _vm.loading,
                  height: _vm.height,
                  tableData: _vm.tableData,
                  pageIndex: _vm.pager.pageIndex,
                  pageSize: _vm.pager.pageSize,
                  isPager: true,
                },
                on: {
                  handlePageChange: _vm.handlePageChange,
                  handleSelectionChange: _vm.handleSelectionChange,
                  sortChange: _vm.sortChange,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "id",
                      fn: function (row) {
                        return [
                          _c("span", [_vm._v(_vm._s(row.scope.id))]),
                          row.scope.subStatus === 1 || row.scope.subStatus === 2
                            ? _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: {
                                    disabled: row.scope.subStatus !== 2,
                                    effect: "dark",
                                    content: row.scope.uploadFailReason,
                                    placement: "bottom",
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      class: {
                                        "order-status order-status-1":
                                          row.scope.subStatus === 1,
                                        "order-status order-status-2":
                                          row.scope.subStatus === 2,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(row.scope.subStatusName) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                          row.scope.status === 2
                            ? [
                                row.scope.subStatus === 6
                                  ? _c("img", {
                                      attrs: {
                                        src: require("@/assets/images/order/subStatus0.png"),
                                      },
                                    })
                                  : _vm._e(),
                                row.scope.subStatus === 5
                                  ? _c("img", {
                                      attrs: {
                                        src: require("@/assets/images/order/subStatus5.png"),
                                      },
                                    })
                                  : _vm._e(),
                              ]
                            : _vm._e(),
                          row.scope.status === 3
                            ? [
                                row.scope.subStatus === 3
                                  ? _c("img", {
                                      attrs: {
                                        src: require("@/assets/images/order/subStatus3.png"),
                                      },
                                    })
                                  : _vm._e(),
                                row.scope.subStatus === 4
                                  ? _c("img", {
                                      attrs: {
                                        src: require("@/assets/images/order/subStatus4.png"),
                                      },
                                    })
                                  : _vm._e(),
                              ]
                            : _vm._e(),
                        ]
                      },
                    },
                    {
                      key: "agentWeight",
                      fn: function (row) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(row.scope.agentWeight) +
                              " " +
                              _vm._s(row.scope.agentWeightUnit) +
                              " "
                          ),
                        ]
                      },
                    },
                    {
                      key: "calculateVolumeWeight",
                      fn: function (row) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(row.scope.calculateVolumeWeight) +
                              " " +
                              _vm._s(row.scope.calculateWeightUnit) +
                              " "
                          ),
                        ]
                      },
                    },
                    {
                      key: "trackingNumber",
                      fn: function (row) {
                        return [
                          _c("div", [
                            _c("span", { staticStyle: { color: "#2395f3" } }, [
                              _vm._v(_vm._s(row.scope.trackingNumber)),
                            ]),
                            _c("img", {
                              staticStyle: { cursor: "pointer" },
                              attrs: {
                                title: "查看轨迹",
                                src: require("@/assets/images/order/cut.png"),
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.viewTrack(row.scope)
                                },
                              },
                            }),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "status",
                      fn: function (row) {
                        return [
                          _c(
                            "div",
                            {
                              class: [
                                "status",
                                "flex_c_c",
                                `status${row.scope.status}`,
                              ],
                            },
                            [
                              _vm._v(
                                _vm._s(_vm._f("formatStatus")(row.scope.status))
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                    {
                      key: "estimateAmount",
                      fn: function (row) {
                        return [
                          _c("p", [
                            _c("span", { staticStyle: { color: "#FF7444" } }, [
                              _vm._v(
                                _vm._s(row.scope.estimateAmount) +
                                  " " +
                                  _vm._s(row.scope.currency)
                              ),
                            ]),
                            _c("img", {
                              staticStyle: { cursor: "pointer" },
                              attrs: {
                                title: "查看费率",
                                src: require("@/assets/images/order/cut1.png"),
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.viewFee(row.scope)
                                },
                              },
                            }),
                          ]),
                          row.scope.otherAmount
                            ? _c("p", { staticClass: "otherFee" }, [
                                _vm._v(
                                  " 附加：" +
                                    _vm._s(row.scope.otherAmount) +
                                    " " +
                                    _vm._s(row.scope.currency) +
                                    " "
                                ),
                              ])
                            : _vm._e(),
                        ]
                      },
                    },
                    {
                      key: "calculateWeight",
                      fn: function (row) {
                        return [
                          _c("span", [
                            _vm._v(
                              _vm._s(row.scope.calculateWeight) +
                                " " +
                                _vm._s(row.scope.calculateWeightUnit)
                            ),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "opt",
                      fn: function (row) {
                        return [
                          _c("div", { staticClass: "table-opt" }, [
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.activeName === 10000,
                                    expression: "activeName === 10000",
                                  },
                                ],
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    directives: [
                                      {
                                        name: "permissson",
                                        rawName: "v-permissson",
                                        value: "detail_a",
                                        expression: "'detail_a'",
                                      },
                                    ],
                                    attrs: { type: "text", size: "small" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.viewDetail(row.scope)
                                      },
                                    },
                                  },
                                  [_vm._v("详情")]
                                ),
                                row.scope.status === 1
                                  ? _c(
                                      "el-button",
                                      {
                                        directives: [
                                          {
                                            name: "permissson",
                                            rawName: "v-permissson",
                                            value: "cancel",
                                            expression: "'cancel'",
                                          },
                                        ],
                                        attrs: { type: "text", size: "small" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.ruduceRow(row.scope)
                                          },
                                        },
                                      },
                                      [_vm._v("取消")]
                                    )
                                  : _vm._e(),
                                row.scope.status === 0
                                  ? _c(
                                      "el-button",
                                      {
                                        directives: [
                                          {
                                            name: "permissson",
                                            rawName: "v-permissson",
                                            value: "delete",
                                            expression: "'delete'",
                                          },
                                        ],
                                        attrs: { type: "text", size: "small" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.deleteRow(row.scope)
                                          },
                                        },
                                      },
                                      [_vm._v("删除")]
                                    )
                                  : _vm._e(),
                                _vm.printPermissson(row.scope.status)
                                  ? _c(
                                      "el-button",
                                      {
                                        directives: [
                                          {
                                            name: "permissson",
                                            rawName: "v-permissson",
                                            value: "print_a",
                                            expression: "'print_a'",
                                          },
                                        ],
                                        attrs: { type: "text", size: "small" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.print(row.scope)
                                          },
                                        },
                                      },
                                      [_vm._v("打印")]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.activeName === 0,
                                    expression: "activeName === 0",
                                  },
                                ],
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    directives: [
                                      {
                                        name: "permissson",
                                        rawName: "v-permissson",
                                        value: "detail_b",
                                        expression: "'detail_b'",
                                      },
                                    ],
                                    attrs: { type: "text", size: "small" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.viewDetail(row.scope)
                                      },
                                    },
                                  },
                                  [_vm._v("详情")]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    directives: [
                                      {
                                        name: "permissson",
                                        rawName: "v-permissson",
                                        value: "delete",
                                        expression: "'delete'",
                                      },
                                    ],
                                    attrs: { type: "text", size: "small" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.deleteRow(row.scope)
                                      },
                                    },
                                  },
                                  [_vm._v("删除")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.activeName === 1,
                                    expression: "activeName === 1",
                                  },
                                ],
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    directives: [
                                      {
                                        name: "permissson",
                                        rawName: "v-permissson",
                                        value: "detail_c",
                                        expression: "'detail_c'",
                                      },
                                    ],
                                    attrs: { type: "text", size: "small" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.viewDetail(row.scope)
                                      },
                                    },
                                  },
                                  [_vm._v("详情")]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    directives: [
                                      {
                                        name: "permissson",
                                        rawName: "v-permissson",
                                        value: "cancel",
                                        expression: "'cancel'",
                                      },
                                    ],
                                    attrs: { type: "text", size: "small" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.ruduceRow(row.scope)
                                      },
                                    },
                                  },
                                  [_vm._v("取消")]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      "v-permissson": "print_c",
                                      type: "text",
                                      size: "small",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.print(row.scope)
                                      },
                                    },
                                  },
                                  [_vm._v("打印")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.activeName === 2,
                                    expression: "activeName === 2",
                                  },
                                ],
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    directives: [
                                      {
                                        name: "permissson",
                                        rawName: "v-permissson",
                                        value: "detail_d",
                                        expression: "'detail_d'",
                                      },
                                    ],
                                    attrs: { type: "text", size: "small" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.viewDetail(row.scope)
                                      },
                                    },
                                  },
                                  [_vm._v("详情")]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      "v-permissson": "print_d",
                                      type: "text",
                                      size: "small",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.print(row.scope)
                                      },
                                    },
                                  },
                                  [_vm._v("打印")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.activeName === 3,
                                    expression: "activeName === 3",
                                  },
                                ],
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    directives: [
                                      {
                                        name: "permissson",
                                        rawName: "v-permissson",
                                        value: "detail_e",
                                        expression: "'detail_e'",
                                      },
                                    ],
                                    attrs: { type: "text", size: "small" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.viewDetail(row.scope)
                                      },
                                    },
                                  },
                                  [_vm._v("详情")]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      "v-permissson": "print_e",
                                      type: "text",
                                      size: "small",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.print(row.scope)
                                      },
                                    },
                                  },
                                  [_vm._v("打印")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.activeName === 4,
                                    expression: "activeName === 4",
                                  },
                                ],
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    directives: [
                                      {
                                        name: "permissson",
                                        rawName: "v-permissson",
                                        value: "detail_f",
                                        expression: "'detail_f'",
                                      },
                                    ],
                                    attrs: { type: "text", size: "small" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.viewDetail(row.scope)
                                      },
                                    },
                                  },
                                  [_vm._v("详情")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.activeName === 10,
                                    expression: "activeName === 10",
                                  },
                                ],
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    directives: [
                                      {
                                        name: "permissson",
                                        rawName: "v-permissson",
                                        value: "delete_h",
                                        expression: "'delete_h'",
                                      },
                                    ],
                                    attrs: { type: "text", size: "small" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.deleteRow(row.scope)
                                      },
                                    },
                                  },
                                  [_vm._v("删除")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.activeName === -1,
                                    expression: "activeName === -1",
                                  },
                                ],
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    directives: [
                                      {
                                        name: "permissson",
                                        rawName: "v-permissson",
                                        value: "detail_g",
                                        expression: "'detail_g'",
                                      },
                                    ],
                                    attrs: { type: "text", size: "small" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.viewDetail(row.scope)
                                      },
                                    },
                                  },
                                  [_vm._v("详情")]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "address",
                      fn: function (row) {
                        return [
                          _c("div", { staticClass: "address" }, [
                            _c("div", { staticClass: "address-content" }, [
                              _c(
                                "span",
                                {
                                  staticClass: "address-tag",
                                  class: _vm.addressTag(row.scope),
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("formatAddress")(
                                          row.scope.classification
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              row.scope.remoteType
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "address-tag address-tag-3",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          row.scope.remoteType === 1
                                            ? "偏远"
                                            : "超偏远"
                                        )
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]),
                            _c("div", { staticClass: "address-detail" }, [
                              _c("p", [
                                _vm._v(
                                  _vm._s(row.scope.addressLine1) +
                                    _vm._s(
                                      row.scope.addressLine2
                                        ? `,${row.scope.addressLine2}`
                                        : ""
                                    )
                                ),
                              ]),
                              _c("p", [
                                _vm._v(
                                  _vm._s(row.scope.state) +
                                    "," +
                                    _vm._s(row.scope.city) +
                                    "," +
                                    _vm._s(row.scope.countryCode) +
                                    ","
                                ),
                                _c("strong", [
                                  _vm._v(_vm._s(row.scope.postalCode)),
                                ]),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      row.scope.amazonCode
                                        ? `,${row.scope.amazonCode}`
                                        : ""
                                    )
                                ),
                              ]),
                            ]),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "custom-total",
                      fn: function () {
                        return [
                          _c("div", { staticClass: "custom-total" }, [
                            _c(
                              "div",
                              {
                                staticClass: "export flex_c_c",
                                on: { click: _vm.batchExport },
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: require("@/assets/images/order/export.png"),
                                  },
                                }),
                                _vm._v("导出 "),
                              ]
                            ),
                            _vm.activeName === 10000 || _vm.activeName === 0
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "upload flex_c_c",
                                    on: { click: _vm.batchUpload },
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: require("@/assets/images/order/upload1.png"),
                                      },
                                    }),
                                    _vm._v("批量上传 "),
                                  ]
                                )
                              : _vm._e(),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  3124561031
                ),
              })
            : _vm._e(),
        ],
        1
      ),
      _c("CustomerListDialog", {
        attrs: {
          dialogVisible: _vm.dialogVisible,
          useFildList: _vm.useFildList,
          useLogId: _vm.useLogId,
        },
        on: {
          ok: _vm.ok,
          cancel: function ($event) {
            _vm.dialogVisible = false
          },
        },
      }),
      _c("track-drawer", {
        attrs: { drawer: _vm.drawer, editData: _vm.editData },
        on: {
          close: function ($event) {
            _vm.drawer = false
          },
        },
      }),
      _c("fee-drawer", {
        attrs: { drawer: _vm.drawer2, orderId: _vm.editData.id },
        on: {
          close: function ($event) {
            _vm.drawer2 = false
          },
        },
      }),
      _c("detail-drawer", {
        attrs: {
          drawer: _vm.drawer1,
          orderId: _vm.editData.id,
          quotesType: _vm.editData.quotesType,
        },
        on: {
          close: function ($event) {
            _vm.drawer1 = false
          },
        },
      }),
      _c("batch-upload-dialog", {
        attrs: {
          dialogVisible: _vm.dialogVisible1,
          selectionItem: _vm.selectionItem,
        },
        on: {
          ok: _vm.ok2,
          cancel: function ($event) {
            _vm.dialogVisible1 = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }