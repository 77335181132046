<template>
  <Drawer :drawer="drawer" :withHeader="false" :size="size" @close="close">
    <div v-show="hideChildOrder" class="view">
      <div class="title">
        <p>
          查看轨迹
        </p>
        <div style="display: flex;align-items: center;">
          <div class="reload" @click="reload">
            <img src="@/assets/images/order/reload.png" />
            刷新轨迹
          </div>
          <i class="el-icon-close closeIcon" @click="close" />
        </div>
      </div>
      <div class="data">
        <BaseTable
          v-if="height > 0"
          :height="height"
          :tableData="tableData"
          :pageIndex="pager.pageIndex"
          :pageSize="pager.pageSize"
          :loading="loading"
          :isPager="true"
          @handlePageChange="handlePageChange"
        >
          <template slot="express" slot-scope="row">
            <img
              v-if="row.scope.express !== 'Other' &&
                row.scope.express !== 'Reserved' &&
                row.scope.express !== 'Owner'"
              width="100"
              :src="require(`@/assets/images/serverSupply/${row.scope.express.toLowerCase()}.png`)"
            />
          </template>
          <template slot="navication" slot-scope="row">
            <div
              :class="['status', `status${row.scope.navication}`]"
            >
              {{ row.scope.navication | formatStatus }}
            </div>
          </template>
          <template slot="track" slot-scope="row">
            <p class="description">{{ row.scope.description }}</p>
            <p class="location">
              {{ row.scope.location }} {{ row.scope.time }}
            </p>
          </template>
          <template slot="opt" slot-scope="row">
            <div class="view-order-track" @click="viewOrder(row.scope)">
              <span>轨迹详情</span>
            </div>
          </template>
        </BaseTable>
      </div>
    </div>
    <div v-show="!hideChildOrder" class="log-body">
      <div class="title">
        <div class="back">
          <div class="back-to" @click="back"><img src="@/assets/images/order/back.png" alt="">返回列表</div>
          <div class="current"><i class="el-icon-arrow-right"></i>轨迹详情</div>
        </div>
        <i class="el-icon-close closeIcon" @click="close" />
      </div>
      <div class="log-body_data">
        <div class="track-num">
          追踪单号：<span class="number">{{ orderData.trackingNumber }}</span>
        </div>
        <div class="track-other">
          <div class="country">
            <span>{{ orderData.originCountryCode }}</span>
            <img src="@/assets/images/order/guide.png" alt="">
            <span>{{ orderData.countryCode }}</span>
          </div>
          <div class="navication">
            <template v-if="orderData.navication <= 2">
              <div class="nav-step-text">
                <span style="margin-right: 40px;">已创建</span>
                <span style="margin-right: 40px;">运输中</span>
                <span>已签收</span>
              </div>
              <div class="nav-step-jd">
                <div class="nav-setp-dot nav-dot-blue"></div>
                <div :class="['nav-setp-line', orderData.navication > 0 ? 'nav-line-blue': '']"></div>
                <div :class="['nav-setp-dot', orderData.navication > 0 ? 'nav-dot-blue': '']"></div>
                <div :class="['nav-setp-line', orderData.navication > 1 ? 'nav-line-blue': '']"></div>
                <div v-if="orderData.navication !== 2" class="nav-setp-dot"></div>
                <img v-if="orderData.navication === 2" src="@/assets/images/order/over.png" alt="">
              </div>
            </template>
            <template v-if="orderData.navication >= 3">
              <div class="nav-step-text">
                <span style="margin-right: 40px;">已创建</span>
                <span v-if="orderData.navication === 3">取消</span>
                <span v-if="orderData.navication === 4">退回</span>
                <span v-if="orderData.navication === 5">其他</span>
              </div>
              <div class="nav-step-jd">
                <div class="nav-setp-dot nav-dot-blue"></div>
                <div :class="['nav-setp-line', orderData.navication > 0 ? 'nav-line-blue': '']"></div>
                <div :class="['nav-setp-dot', orderData.navication > 0 ? 'nav-dot-red': '']"></div>
              </div>
            </template>
          </div>
        </div>
        <div class="track-step">
          <div v-for="(item, index) in orderData.trackInfo" :key="index" class="step-item">
            <div :class="['step-title', index === 0 ? 'blue' : '']">
              <div class="dot">
                <div :class="['step-dot', index === 0 ? 'blue-dot' : '']"></div>
              </div>
              <div class="step-time">{{ item.localTime }}&nbsp;&nbsp;{{ item.location }}</div>
            </div>
            <div :class="['step-info', index === 0 ? 'blue' : '']">
              {{ item.description }}
            </div>
            <div v-if="index !== orderData.trackInfo.length - 1" :class="['step-line', , index === 0 ? 'blue-line' : '']"></div>
          </div>
        </div>
      </div>
    </div>
  </Drawer>
</template>

<script>
const Drawer = () => import('@/components/drawer/Drawer')
const BaseTable = () => import('@/components/table/BaseTable')
import { trackOverview, refreshOrderTrack } from '@/api/fee'
import { trackEvents } from '@/api/basic'
import config from '@/utils/config'
export default {
  components: { Drawer, BaseTable },
  filters: {
    formatStatus(val) {
      const arr = ['已创建', '运输中', '签收', '取消', '退回', '其他']
      const str = arr.find((a, i) => +val === i)
      return str
    }
  },
  props: {
    drawer: {
      type: Boolean,
      default: false
    },
    editData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      config,
      height: 0,
      tableData: {
        fileds: [
          {
            filed: 'express',
            label: '快递服务商',
            width: '120',
            isCustom: true
          },
          {
            filed: 'referenceNumber',
            label: '客户单号',
            width: '180',
            isCopy: true
          },
          {
            filed: 'trackingNumber',
            label: '追踪单号',
            width: '180',
            isCopy: true
          },
          {
            filed: 'navication',
            label: '最新状态',
            width: '150',
            isCustom: true
          },
          {
            filed: 'track',
            label: '最新轨迹',
            isCustom: true
          },
          {
            filed: 'opt',
            label: '操作',
            width: '120',
            isCustom: true
          }
        ],
        columns: [],
        total: 0
      },
      pager: {
        pageIndex: 1,
        pageSize: 20
      },
      size: 1100,
      loading: false,
      orderData: {},
      hideChildOrder: true
    }
  },
  watch: {
    drawer(val) {
      if (val) {
        this.getList({
          pageIndex: 1,
          pageSize: 20
        })
        this.pager = {
          pageIndex: 1,
          pageSize: 20
        }
      }
    }
  },
  mounted() {
    this.height = document.querySelector('.data').offsetHeight - 43
  },
  methods: {
    async getList(params) {
      try {
        params = {
          ...params,
          queryParam: this.editData.id
        }
        const res = await trackOverview(params)
        if (res.success) {
          this.tableData.columns = res.data.list
          this.tableData.total = res.data.total
        } else {
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        console.error(error)
      }
    },
    close() {
      this.hideChildOrder = true
      this.size = 1100
      this.$emit('close')
    },
    handlePageChange(pager) {
      this.pager = pager
      this.getList(pager)
    },
    async viewOrder(row) {
      // if (this.editData.status === 1) {

      //   this.hideChildOrder = false
      //   this.size = 520
      // } else {
        try {
          const res = await trackEvents({
            supplier: this.config.supplyCode,
            scope: 0,
            orderId: row.orderId
          })
          if (res.success) {
            this.orderData = {
              ...res.data[0],
              originCountryCode: row.originCountryCode,
              countryCode: row.countryCode,
              navication: res.data[0].lastNavication,
              trackInfo: res.data[0].events && res.data[0].events.map(a => {
                a.localTime = /\d{4}-\d{1,2}-\d{1,2}[ ]\d{1,2}:\d{1,2}/g.exec(a.localTime)[0]
                return a
              })
            }
          } else {
            // this.$message.error(res.errorMessage)
            this.orderData = {
              originCountryCode: row.originCountryCode,
              countryCode: row.countryCode,
              trackingNumber: row.trackingNumber,
              navication: 0,
              trackInfo: [
                { localTime: row.time, location: row.location, description: row.description }
              ]
            }
          }
          this.hideChildOrder = false
          this.size = 520
        } catch (error) {
          console.error(error)
        }
      // }
    },
    back() {
      this.hideChildOrder = true
      this.size = 1100
    },
    async reload() {
      try {
        const res = await refreshOrderTrack({
          orderId: this.editData.id
        })
        if (res.success) {
          this.$message.success('刷新成功')
          this.getList({
            pageIndex: 1,
            pageSize: 20
          })
          this.pager = {
            pageIndex: 1,
            pageSize: 20
          }
        } else {
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.view {
  width: 100%;
  height: 100%;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  .data {
    width: 100%;
    height: calc(100vh - 120px);
    margin: 20px auto;
    padding: 0 20px 20px;
    box-sizing: border-box;
    overflow-y: auto;
  }
  .status {
    width: 60px;
    height: 26px;
    border-radius: 4px;
    margin: 0 auto;
    line-height: 26px;
  }
  .status0 {
    background: rgba(255, 116, 68, 0.1);
    color: #ff7444
  }
  .status1 {
    background: rgba(53, 186, 131, 0.1);
    color: #35ba83
  }
  .status2 {
    background: rgba(254, 170, 79, 0.1);
    color: #feaa4f
  }
  .status3 {
    background: rgba(153, 153, 153, 0.1);
    color: #999999
  }
  .status4 {
    background: rgba(254, 69, 61, 0.1);
    color: #fe453d
  }
  .status5 {
    background: rgba(157, 103, 254, 0.1);
    color: #9d67fe
  }
  .description {
    height: 16px;
    font-size: 12px;
    font-weight: bold;
    color: #666666;
    line-height: 16px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .location {
    height: 16px;
    font-size: 12px;
    font-weight: 400;
    color: #666666;
    line-height: 16px;
  }
  .view-order-track {
    color: #2395f3;
    cursor: pointer;
    img {
      vertical-align: text-top;
    }
  }
}
.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 60px;
  font-size: 16px;
  font-weight: 400;
  color: #333333;
  padding: 0 20px;
  border-bottom: 1px solid #F7F7F7;
  box-sizing: border-box;
  .closeIcon {
    cursor: pointer;
  }
}
.reload {
  width: 96px;
  height: 32px;
  border-radius: 4px 4px 4px 4px;
  border: 1px solid rgba(35, 149, 243, 0.4);
  font-size: 14px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #2395f3;
  line-height: 32px;
  text-align: center;
  cursor: pointer;
  margin-right: 20px;
  img {
    vertical-align: -5px;
  }
}
.log-body {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  .back {
    display: flex;
    align-items: center;
    .back-to {
      height: 21px;
      font-size: 16px;
      font-weight: 400;
      color: #2395F3;
      line-height: 21px;
      cursor: pointer;
      img {
        margin-right: 10px;
      }
    }
    .current {
      height: 21px;
      font-size: 16px;
      font-weight: 400;
      color: #666666;
      line-height: 21px;
      margin-left: 10px;
      i {
        margin-right: 10px;
      }
    }
  }
  .log-body_data {
    width: 100%;
    height: calc(100vh - 120px);
    margin: 20px auto;
    padding: 0 20px 20px;
    box-sizing: border-box;
    overflow-y: auto;
  }
  .track-num {
    width: 480px;
    height: 60px;
    line-height: 60px;
    text-align: center;
    background: #F7F7F7;
    border-radius: 6px 6px 6px 6px;
    font-size: 16px;
    font-weight: 400;
    .number {
      color: #2395F3;
    }
  }
  .track-other {
    display: flex;
    width: 480px;
    height: 80px;
    margin-top: 10px;
    .country {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 180px;
      height: 100%;
      background: #F7F7F7;
      border-radius: 6px 6px 6px 6px;
      opacity: 1;
      margin-right: 10px;
      font-size: 18px;
      font-weight: bold;
      color: #2395F3;
      img {
        margin: 0 24px;
      }
    }
    .navication {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 290px;
      height: 100%;
      background: #F7F7F7;
      border-radius: 6px 6px 6px 6px;
      opacity: 1;
      .nav-step-text {
        font-size: 14px;
        font-weight: 400;
        color: #333333;
      }
      .nav-step-jd {
        display: flex;
        align-items: center;
        margin-top: 8px;
        .nav-setp-dot {
          width: 8px;
          height: 8px;
          background: #CDCDCD;
          border-radius: 50px;
          opacity: 1;
        }
        .nav-dot-blue {
          background: #2395F3;
        }
        .nav-dot-red {
          background: red;
        }
        .nav-setp-line {
          width: 42px;
          border-bottom: 1px solid #CDCDCD;
          margin: 0 16px;
        }
        .nav-line-blue {
          border-bottom: 1px solid #2395F3;
        }
      }
    }
  }
  .track-step {
    width: 480px;
    height: auto;
    min-height: 438px;
    background: #FBFBFB;
    border-radius: 6px 6px 6px 6px;
    opacity: 1;
    margin-top: 20px;
    padding: 22px 0 0 20px;
    box-sizing: border-box;
    .step-item {
      position: relative;
      height: auto;
      color: #999999;
      .step-title {
        display: flex;
        align-items: center;
        position: relative;
        height: 16px;
        .dot {
          display: flex;
          justify-content: center;
          width: 12px;
        }
        .step-dot {
          width: 8px;
          height: 8px;
          background: #CDCDCD;
          border-radius: 50px;
        }
        .blue-dot {
          width: 12px;
          height: 12px;
          background: #2395F3;
        }
        .step-time {
          position: absolute;
          left: 26px;
          width: calc(100% - 55px);
          font-size: 12px;
          font-weight: 400;
          overflow:hidden;
          text-overflow:ellipsis;
          white-space:nowrap;
        }
      }
      .step-info {
        font-size: 14px;
        font-weight: bold;
        color: #999999;
        line-height: 19px;
        margin: 8px 0 0 26px;
        padding-bottom: 24px;
        box-sizing: border-box;
      }
      .step-line {
        position: absolute;
        top: 10px;
        left: 6px;
        height: 100%;
        border-left: 1px solid #CDCDCD;
      }
      .blue {
        color: #2395F3;
      }
      .blue-line {
        border-left: 1px solid #2395F3;
      }
    }
  }
}
</style>
