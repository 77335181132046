var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Drawer",
    {
      attrs: { drawer: _vm.drawer, title: "查看费率", size: 830 },
      on: { close: _vm.close },
    },
    [
      _c(
        "div",
        { staticClass: "view" },
        _vm._l(_vm.orderFee, function (order, index) {
          return _c("div", { key: index, staticClass: "panel" }, [
            _c("div", { staticClass: "header" }, [
              _c("span", { staticStyle: { "margin-left": "20px" } }, [
                _vm._v(_vm._s(order.lable)),
              ]),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.stretchArr[index],
                      expression: "!stretchArr[index]",
                    },
                  ],
                  staticClass: "btn",
                  on: {
                    click: function ($event) {
                      return _vm.changeStretch(index)
                    },
                  },
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/images/order/zhankai.png"),
                    },
                  }),
                  _vm._v(" 展开 "),
                ]
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.stretchArr[index],
                      expression: "stretchArr[index]",
                    },
                  ],
                  staticClass: "btn",
                  on: {
                    click: function ($event) {
                      return _vm.changeStretch(index)
                    },
                  },
                },
                [
                  _c("img", {
                    attrs: { src: require("@/assets/images/order/shouqi.png") },
                  }),
                  _vm._v(" 收起 "),
                ]
              ),
            ]),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.stretchArr[index],
                    expression: "stretchArr[index]",
                  },
                ],
                staticClass: "showList",
              },
              [
                _c(
                  "div",
                  { staticClass: "content" },
                  _vm._l(order.dataItems, function (item, $index) {
                    return _c(
                      "div",
                      {
                        key: $index,
                        staticClass: "sentType",
                        class: [{ active: _vm.activeArr[index] === $index }],
                      },
                      [
                        _c("div", { staticClass: "chooseItem" }, [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/images/gou.png"),
                              width: "16",
                              height: "14",
                            },
                          }),
                        ]),
                        item.remoteType
                          ? _c("div", { staticClass: "remoteType flex_c_c" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    item.remoteType === 1 ? "偏远" : "超偏远"
                                  ) +
                                  " "
                              ),
                            ])
                          : _vm._e(),
                        _c("div", { staticClass: "form" }, [
                          _c("img", {
                            staticStyle: {
                              "margin-bottom": "12px",
                              "margin-top": "15px",
                            },
                            attrs: {
                              src: require(`@/assets/images/serverSupply/${item.express.toLowerCase()}.png`),
                              width: "100",
                              height: "33",
                            },
                          }),
                          _c("div", { staticClass: "item" }, [
                            _c("p", [_vm._v("服务类型")]),
                            _c("p", { staticClass: "blue" }, [
                              _vm._v(_vm._s(item.service)),
                            ]),
                          ]),
                          _c("div", { staticClass: "item" }, [
                            _c("p", [_vm._v("件数")]),
                            _c("p", { staticClass: "blue" }, [
                              _vm._v(_vm._s(item.qty)),
                            ]),
                          ]),
                          _c("div", { staticClass: "item" }, [
                            _c("p", [_vm._v("计费重量")]),
                            _c("p", { staticClass: "blue" }, [
                              _vm._v(_vm._s(item.weightKG) + " KG"),
                            ]),
                          ]),
                          _c("div", { staticClass: "item" }, [
                            _c("p", [_vm._v("预估费用")]),
                            _c(
                              "p",
                              { staticClass: "red" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("formatterCurrency")(item.currency)
                                    ) +
                                    " " +
                                    _vm._s(item.estimateAmount) +
                                    " "
                                ),
                                _c(
                                  "el-popover",
                                  {
                                    attrs: {
                                      placement: "bottom",
                                      trigger: "hover",
                                    },
                                  },
                                  [
                                    _c(
                                      "ul",
                                      _vm._l(item.rateItems, function (a, i) {
                                        return _c(
                                          "li",
                                          {
                                            key: i,
                                            staticClass: "detail-item",
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  "margin-left": "16px",
                                                },
                                              },
                                              [_vm._v(_vm._s(a.name) + "：")]
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticClass: "detail-money",
                                                staticStyle: {
                                                  "margin-right": "16px",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm._f("formatterCurrency")(
                                                      item.currency
                                                    )
                                                  ) +
                                                    " " +
                                                    _vm._s(a.surcharge.amount)
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      }),
                                      0
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "tips",
                                        attrs: { slot: "reference" },
                                        slot: "reference",
                                      },
                                      [
                                        _c("svg-icon", {
                                          attrs: {
                                            iconClass: "detail",
                                            className: "icon",
                                          },
                                        }),
                                        _vm._v("明细"),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c("div", { staticClass: "item" }, [
                            _c("p", [_vm._v("RMB费用")]),
                            _c("p", { staticClass: "red" }, [
                              _vm._v("¥ " + _vm._s(item.estimateAmountRMB)),
                            ]),
                          ]),
                          _c("div", { staticClass: "item" }, [
                            _c("p", [_vm._v("RMB费用/KG")]),
                            _c("p", { staticClass: "red" }, [
                              _vm._v(
                                "¥ " + _vm._s(item.perKgEstimateAmountRMB)
                              ),
                            ]),
                          ]),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.stretchArr[index],
                    expression: "!stretchArr[index]",
                  },
                ],
                staticClass: "hideList",
              },
              [_vm._v(" 共 " + _vm._s(order.dataItems.length) + " 个服务 ")]
            ),
          ])
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }