<template>
  <div class="contents">
    <div class="header">
      <div class="tab">
        <span
          v-for="(item, $index) in statusOptions"
          :key="$index"
          v-permissson="`${item.permissson}`"
          :class="['tabItem', { active: activeName === item.value }]"
          @click="handleClick(item.value)"
        >{{ item.value === 10000 ? `${item.label}(${tabTotal})` : `${item.label}(${tabNumer?.find(a => a.type === +item.value)?.qty || 0})` }}</span>
      </div>
      <div class="opt">
        <div v-if="filterList.date" class="checkDate flex_c_c">
          查询时间：{{ filterList.date[0] + '~' + filterList.date[1] }}
        </div>
        <div class="search">
          <el-popover
            ref="popover"
            placement="top"
            class="searchPop"
            title="批量搜索"
            trigger="click"
            width="378"
          >
            <div class="close" @click="closeSearchPanel">
              <i class="el-icon-close"></i>
            </div>
            <base-muti-input
              ref="baseMutiInput"
              v-model="referenceNumber"
              :default="referenceNumber"
            ></base-muti-input>
            <div style="text-align: right; margin: 16px 0 0">
              <el-button size="small" @click="handleEmpty">清空</el-button>
              <el-button
                size="small"
                type="primary"
                @click="handleConfirm"
              >确定</el-button
              >
            </div>
          </el-popover>
          <el-input
            :value="searchVal"
            placeholder="请输入单号搜索"
            class="input-search"
            @focus="handleFocus"
          ></el-input>
          <div class="btn-search" @click="handleConfirm">
            <i class="el-icon-search"></i>
          </div>
        </div>
        <div class="import" @click="batchExport">
          <img src="@/assets/images/bill/import.png" alt="" />导出
        </div>
        <template v-for="item in customList">
          <template>
            <div
              v-show="(activeName === item.index)"
              :key="`${item.permissson}`"
              v-permissson="`${item.permissson}`"
              class="customer-list"
              @click="customerList"
            >
              <img src="@/assets/images/order/setting.png" />
              自定义列表
            </div>
          </template>
        </template>
        <div class="filter">
          <base-filter
            ref="BaseFilter"
            :width="864"
            :isActive="isActive"
            @sendFilter="sendFilter"
            @reset="reset"
          >
            <div class="filter">
              <el-form
                ref="form"
                label-position="right"
                :model="filterList"
                label-width="70px"
              >
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="创建日期">
                      <el-date-picker
                        v-model="filterList.date"
                        style="width: 320px"
                        type="daterange"
                        range-separator="-"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        value-format="yyyy-MM-dd"
                        unlink-panels
                        :picker-options="pickerOptions"
                      >
                      </el-date-picker>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="省州">
                      <el-input
                        v-model="filterList.state"
                        placeholder="请输入省州"
                        style="width: 320px"
                      />
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="始发地">
                      <el-select
                        v-model="filterList.originCityId"
                        style="width: 320px"
                        placeholder="请选择始发地"
                      >
                        <el-option
                          v-for="item in originCountryOptions"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="邮编">
                      <el-input
                        v-model="filterList.postalCode"
                        placeholder="请输入邮编"
                        style="width: 320px"
                      />
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="12">
                    <el-row>
                      <el-col :span="24">
                        <el-form-item label="国家">
                          <el-select
                            v-model="filterList.countryCode"
                            style="width: 320px"
                            multiple
                            placeholder="请选择国家"
                          >
                            <el-option
                              v-for="item in addressDropCodeOption"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value"
                            >
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="24">
                        <el-form-item label="派送方式">
                          <el-select
                            v-model="filterList.expressSupplier"
                            style="width: 320px"
                            placeholder="请选择派送方式"
                          >
                            <el-option
                              v-for="item in esIdOptions"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value"
                            >
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="24">
                        <el-form-item label="服务类型">
                          <el-select
                            v-model="filterList.expressService"
                            style="width: 320px"
                            placeholder="请选择服务类型"
                          >
                            <el-option
                              v-for="item in esServiceIdOptions"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value"
                            >
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row v-if="activeName === 10000">
                      <el-col :span="24">
                        <el-form-item label="状态">
                          <el-select
                            v-model="filterList.status"
                            style="width: 320px"
                            multiple
                            placeholder="请选择状态"
                          >
                            <el-option
                              v-for="item in config.orderStatusOptions.slice(1)"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value"
                            >
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </el-col>
                  <el-col :span="12">
                    <!-- <el-row>
                      <el-col :span="24">
                        <el-form-item label="单号">
                          <base-muti-input
                            ref="baseMutiInput"
                            v-model="filterList.referenceNumber"
                            style="width: 320px"
                          ></base-muti-input>
                        </el-form-item>
                      </el-col>
                    </el-row> -->
                    <el-row>
                      <el-col :span="24">
                        <el-form-item label="业务员">
                          <el-select
                            v-model="filterList.saleIds"
                            style="width: 320px"
                            filterable
                            placeholder="请选择"
                            multiple
                          >
                            <el-option
                              v-for="item in userOptions"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value"
                            >
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="24">
                        <el-form-item label="客户">
                          <el-select
                            v-model="filterList.companyIds"
                            style="width: 320px;"
                            filterable
                            placeholder="请选择客户"
                            multiple
                          >
                            <el-option
                              v-for="item in companyDrapdown"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value"
                            >
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="24">
                        <el-form-item label="账号搜索">
                          <el-input
                            v-model="filterList.accountNumber"
                            style="width: 320px"
                            placeholder="请输入"
                          >
                          </el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-form>
            </div>
          </base-filter>
        </div>
      </div>
    </div>
    <div class="data">
      <!-- :key="activeName" -->
      <BaseTable
        v-if="height"
        :loading="loading"
        :height="height"
        :tableData="tableData"
        :pageIndex="pager.pageIndex"
        :pageSize="pager.pageSize"
        :isPager="true"
        @handlePageChange="handlePageChange"
        @handleSelectionChange="handleSelectionChange"
        @sortChange="sortChange"
      >
        <template slot="id" slot-scope="row">
          <span>{{ row.scope.id }}</span>
          <el-tooltip
            v-if="row.scope.subStatus === 1 || row.scope.subStatus === 2"
            :disabled="row.scope.subStatus !== 2"
            class="item"
            effect="dark"
            :content="row.scope.uploadFailReason"
            placement="bottom"
          >
            <span
              :class="{
                'order-status order-status-1': row.scope.subStatus === 1,
                'order-status order-status-2': row.scope.subStatus === 2,
              }"
            >
              {{ row.scope.subStatusName }}
            </span>
          </el-tooltip>
          <template v-if="row.scope.status === 2">
            <img v-if="row.scope.subStatus === 6" src="@/assets/images/order/subStatus0.png">
            <img v-if="row.scope.subStatus === 5" src="@/assets/images/order/subStatus5.png">
          </template>
          <template v-if="row.scope.status === 3">
            <img v-if="row.scope.subStatus === 3" src="@/assets/images/order/subStatus3.png">
            <img v-if="row.scope.subStatus === 4" src="@/assets/images/order/subStatus4.png">
          </template>
        </template>
        <template slot="agentWeight" slot-scope="row">
          <span>{{ row.scope.agentWeight }} {{ row.scope.agentWeightUnit }}</span>
        </template>
        <template slot="calculateVolumeWeight" slot-scope="row">
          <span>{{ row.scope.calculateVolumeWeight }} M<sup>3</sup></span>
        </template>
        <template slot="trackingNumber" slot-scope="row">
          <div>
            <span style="color: #2395f3">{{ row.scope.trackingNumber }}</span>
            <img
              title="查看轨迹"
              src="@/assets/images/order/cut.png"
              style="cursor: pointer"
              @click="viewTrack(row.scope)"
            />
          </div>
        </template>
        <template slot="status" slot-scope="row">
          <div :class="['status', 'flex_c_c', `status${row.scope.status}`]">{{ row.scope.status | formatStatus }}</div>
        </template>
        <template slot="estimateAmount" slot-scope="row">
          <p>
            <span style="color: #FF7444">{{ row.scope.estimateAmount }} {{ row.scope.currency }}</span>
            <img
              style="cursor: pointer"
              title="查看费率"
              src="@/assets/images/order/cut1.png"
              @click="viewFee(row.scope)"
            />
          </p>
          <p v-if="row.scope.otherAmount" class="otherFee">
            附加：{{ row.scope.otherAmount }} {{ row.scope.currency }}
          </p>
        </template>
        <template slot="agentWeight" slot-scope="row">
          {{ row.scope.agentWeight }} {{ row.scope.agentWeightUnit }}
        </template>
        <template slot="calculateVolumeWeight" slot-scope="row">
          {{ row.scope.calculateVolumeWeight }} {{ row.scope.calculateWeightUnit }}
        </template>
        <template slot="calculateWeight" slot-scope="row">
          <span>{{ row.scope.calculateWeight }} {{ row.scope.calculateWeightUnit }}</span>
        </template>
        <template slot="opt" slot-scope="row">
          <div class="table-opt">
            <div v-show="activeName === 10000">
              <el-button
                v-permissson="'detail_a'"
                type="text"
                size="small"
                @click="viewDetail(row.scope)"
              >详情</el-button
              >
              <el-button
                v-if="row.scope.status === 1"
                v-permissson="'cancel'"
                type="text"
                size="small"
                @click="ruduceRow(row.scope)"
              >取消</el-button
              >
              <el-button
                v-if="row.scope.status === 0 "
                v-permissson="'delete'"
                type="text"
                size="small"
                @click="deleteRow(row.scope)"
              >删除</el-button
              >
              <el-button
                v-if="printPermissson(row.scope.status)"
                v-permissson="'print_a'"
                type="text"
                size="small"
                @click="print(row.scope)"
              >打印</el-button
              >
            </div>
            <div v-show="activeName === 0">
              <el-button
                v-permissson="'detail_b'"
                type="text"
                size="small"
                @click="viewDetail(row.scope)"
              >详情</el-button
              >
              <el-button
                v-permissson="'delete'"
                type="text"
                size="small"
                @click="deleteRow(row.scope)"
              >删除</el-button
              >
            </div>
            <div v-show="activeName === 1">
              <el-button
                v-permissson="'detail_c'"
                type="text"
                size="small"
                @click="viewDetail(row.scope)"
              >详情</el-button
              >
              <el-button
                v-permissson="'cancel'"
                type="text"
                size="small"
                @click="ruduceRow(row.scope)"
              >取消</el-button>
              <el-button
                :v-permissson="'print_c'"
                type="text"
                size="small"
                @click="print(row.scope)"
              >打印</el-button>
            </div>
            <div v-show="activeName === 2">
              <el-button
                v-permissson="'detail_d'"
                type="text"
                size="small"
                @click="viewDetail(row.scope)"
              >详情</el-button
              >
              <el-button
                :v-permissson="'print_d'"
                type="text"
                size="small"
                @click="print(row.scope)"
              >打印</el-button>
            </div>
            <div v-show="activeName === 3">
              <el-button
                v-permissson="'detail_e'"
                type="text"
                size="small"
                @click="viewDetail(row.scope)"
              >详情</el-button>
              <el-button
                :v-permissson="'print_e'"
                type="text"
                size="small"
                @click="print(row.scope)"
              >打印</el-button>
            </div>
            <div v-show="activeName === 4">
              <el-button
                v-permissson="'detail_f'"
                type="text"
                size="small"
                @click="viewDetail(row.scope)"
              >详情</el-button>
            </div>
            <div v-show="activeName === 10">
              <!-- <el-button
                v-permissson="'detail_h'"
                type="text"
                size="small"
                @click="viewDetail(row.scope)"
              >详情</el-button> -->
              <el-button
                v-permissson="'delete_h'"
                type="text"
                size="small"
                @click="deleteRow(row.scope)"
              >删除</el-button
              >
            </div>
            <div v-show="activeName === -1">
              <el-button
                v-permissson="'detail_g'"
                type="text"
                size="small"
                @click="viewDetail(row.scope)"
              >详情</el-button>
            </div>
          </div>
        </template>
        <template slot="address" slot-scope="row">
          <div class="address">
            <div class="address-content">
              <span class="address-tag" :class="addressTag(row.scope)">
                {{ row.scope.classification | formatAddress }}
              </span>
              <span v-if="row.scope.remoteType" class="address-tag address-tag-3">{{ row.scope.remoteType === 1 ? '偏远' : '超偏远' }}</span>
            </div>
            <div class="address-detail">
              <p>{{ row.scope.addressLine1 }}{{ row.scope.addressLine2 ? `,${row.scope.addressLine2}` : '' }}</p>
              <p>{{ row.scope.state }},{{ row.scope.city }},{{ row.scope.countryCode }},<strong>{{ row.scope.postalCode }}</strong> {{ row.scope.amazonCode ? `,${row.scope.amazonCode}` :'' }}</p>
            </div>
          </div>
        </template>
        <template #custom-total>
          <div class="custom-total">
            <div class="export flex_c_c" @click="batchExport">
              <img src="@/assets/images/order/export.png" />导出
            </div>
            <div
              v-if="activeName === 10000 || activeName === 0"
              class="upload flex_c_c"
              @click="batchUpload"
            >
              <img src="@/assets/images/order/upload1.png" />批量上传
            </div>
          </div>
        </template>
      </BaseTable>
    </div>
    <CustomerListDialog
      :dialogVisible="dialogVisible"
      :useFildList="useFildList"
      :useLogId="useLogId"
      @ok="ok"
      @cancel="dialogVisible = false"
    ></CustomerListDialog>
    <track-drawer
      :drawer="drawer"
      :editData="editData"
      @close="drawer = false"
    >
    </track-drawer>
    <fee-drawer
      :drawer="drawer2"
      :orderId="editData.id"
      @close="drawer2 = false"
    >
    </fee-drawer>
    <detail-drawer
      :drawer="drawer1"
      :orderId="editData.id"
      :quotesType="editData.quotesType"
      @close="drawer1 = false"
    >
    </detail-drawer>
    <batch-upload-dialog
      :dialogVisible="dialogVisible1"
      :selectionItem="selectionItem"
      @ok="ok2"
      @cancel="dialogVisible1 = false"
    ></batch-upload-dialog>
  </div>
</template>

<script>
import { permissions, getRecentMonthDate, treeToList } from '@/utils/common'
const BaseFilter = () => import('@/components/filter/BaseFilter')
const BaseTable = () => import('@/components/table/BaseTable')
const BaseMutiInput = () => import('@/components/base/BaseMutiInput')
const CustomerListDialog = () =>
  import('@/components/dialog/CustomerListDialog.vue')
import TrackDrawer from './components/TrackDrawer.vue'
import FeeDrawer from './components/FeeDrawer.vue'
import DetailDrawer from './components/DetailDrawer.vue'
import BatchUploadDialog from './components/BatchUploadDialog.vue'
import { getOrderPageList, cancel, deleteOrder } from '@/api/fee'
import { getSysTableUser, getSaleList, getExpressSupplierList } from '@/api/system'
import config from '@/utils/config'
import axios from 'axios'
import dayjs from 'dayjs'
import { mapGetters } from 'vuex'
import { myMixins } from '@/mixins'
export default {
  name: 'OrderSearch',
  components: {
    BaseFilter,
    BaseTable,
    BaseMutiInput,
    CustomerListDialog,
    TrackDrawer,
    FeeDrawer,
    DetailDrawer,
    BatchUploadDialog
  },
  filters: {
    formatStatus: function(value) {
      if (value || value === 0) {
        const item = config.orderStatusOptions.find(
          (a) => parseInt(a.value, 10) === value
        )
        return item.label
      } else {
        return ''
      }
    },
    formatAddress(val) {
      const obj = {
        0: '商业',
        1: '混合',
        2: '住宅',
        3: '未知'
      }
      return obj[val]
    }
    // formatSubStatus(value) {
    //   let str = ''
    //   switch (value) {
    //     case 1: str = '上传中'; break
    //     case 2: str = '上传失败'; break
    //   }
    //   return str
    // }
  },
  mixins: [myMixins],
  data() {
    return {
      pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }]
        },
      config,
      height: 0,
      activeName: 10000,
      pager: {
        pageIndex: 1,
        pageSize: 20
      },
      loading: false,
      filterList: {
        date: '',
        // referenceNumber: '',
        status: '',
        countryCode: '',
        state: '',
        postalCode: '',
        expressService: '',
        expressSupplier: '',
        originCityId: '',
        saleIds: '',
        accountNumber: '',
        companyIds: []
      },
      isActive: false,
      dialogVisible: false,
      dialogVisible1: false,
      selectionItem: [],
      drawer: false,
      drawer1: false,
      drawer2: false,
      useFildList: [],
      userOptions: [],
      tabNumer: [],
      tabTotal: 0,
      useLogId: '0',
      statusOptions: config.orderStatusOptions,
      // expressSupplierOptions: [
      //   {
      //     label: '全部',
      //     value: ''
      //   },
      //   ...config.expressSupplierOptions
      // ],
      // expressServiceOptions: [
      //   {
      //     label: '全部',
      //     value: ''
      //   },
      //   ...config.expressServiceOptions
      // ],
      editData: {},
      tableResData: {
        columns: [],
        total: 0
      },
      sortable: {
        sortField: undefined,
        sortBy: undefined
      },
      customList: [
        { index: 10000, permissson: 'customList_a' },
        { index: 0, permissson: 'customList_b' },
        { index: 1, permissson: 'customList_c' },
        { index: 2, permissson: 'customList_d' },
        { index: 3, permissson: 'customList_e' },
        { index: 4, permissson: 'customList_f' },
        { index: -1, permissson: 'customList_g' }
      ],
      originCountryOptions: [
        {
          label: '全部',
          value: ''
        },
        ...config.originCountryOptions
      ],
      searchVal: '',
      referenceNumber: '',
      supplyService: []
    }
  },
  computed: {
    ...mapGetters(['addressDropOption', 'companyDrapdown']),
    tableData() {
      const isCustomArr = ['id', 'trackingNumber', 'agentWeight', 'calculateVolumeWeight', 'calculateWeight', 'status', 'estimateAmount', 'address', 'agentWeight']
      const isTime = ['createdOn', 'uploadOn', 'updatedOn']
      const isSortArr = ['countryCode', 'state', 'status', 'createdOn', 'updatedOn']
      const isCopy = ['id', 'referenceNumber', 'trackingNumber']
      const isAlign = ['id', 'referenceNumber', 'trackingNumber']
      const isAddress = ['originCityId']
      const arr = this.useFildList.filter((a) => a.selected === true)
      const newArr = arr.map((item) => {
        return {
          filed: item.filed,
          label: item.label,
          width: item.width,
          isSort: isSortArr.indexOf(item.filed) > -1, // 是否支持过滤
          align: isAlign.indexOf(item.filed) > -1 && 'left',
          fixed: item.isFixed, // 是否固定 true, left, right
          isCustom: isCustomArr.indexOf(item.filed) > -1, // 是否自定义列显示
          isCopy: isCopy.indexOf(item.filed) > -1,
          formatter: (row) => {
            if (isTime.indexOf(item.filed) > -1) {
              return row[item.filed] ? row[item.filed].slice(0, row[item.filed].length - 3) : ''
            } else {
              if (isAddress.indexOf(item.filed) > -1) {
                const addressList = treeToList(this.addressDropOption, 'children')
                const country = addressList.find(a => a.value === row[item.filed])
                return country?.label
              } else {
                return row[item.filed]
              }
            }
          }
        }
      })
      newArr.push({
        filed: 'opt',
        label: '操作',
        width: '180',
        isCustom: true,
        fixed: 'right'
      })
      console.log('newArr', newArr)
      return {
        fileds: newArr,
        ...this.tableResData,
        mutiCheck: true
      }
    },
    addressDropCodeOption() {
      return (
        this.addressDropOption &&
        this.addressDropOption.map((item) => {
          return {
            value: item.code,
            label: item.label
          }
        })
      )
    },
    printPermissson() {
      return function(v) {
        const perNumber = [1, 2, 3]
        if (perNumber.includes(v)) {
          return true
        } else {
          return false
        }
      }
    },
    esIdOptions: function() {
      const arr = this.supplyService.map((item) => {
        return {
          label: item.name,
          value: item.name
        }
      })
      arr.unshift({
        label: '全部',
        value: ''
      })
      return arr || []
    },
    esServiceIdOptions: function() {
      const service = this.supplyService.find((item) => {
        if (this.filterList.expressSupplier) {
          return item.name === this.filterList.expressSupplier
        } else {
          return item.name === this.filterList.expressSupplier
        }
      })
      const arr =
        service &&
        service.serviceTypeList.map((item) => {
          return {
            label: item.name,
            value: item.name
          }
        })
        arr && arr.unshift({
        label: '全部',
        value: ''
      })
      return arr || []
    }
  },
  watch: {
    'filterList.expressSupplier'() {
      this.filterList.expressService = ''
    }
  },
  created() {
    this.filterList.date = getRecentMonthDate(1)
    if (!this.companyDrapdown.length) {
      this.$store.dispatch('getCompanyDrapdown')
    }
    this.getUserList()
  },
  mounted() {
    if (!this.addressDropOption || this.addressDropOption.length === 0) {
      this.$store.dispatch('getAddress')
    }
    if (!this.$store.state.connect.connect.tabCode) {
      const menuList = this.$store.getters.initRouter
      const menu = permissions(this.$route.meta.code, menuList)
      const codeLits = [
        [(name) => name === 'QB', () => 10000],
        [(name) => name === 'DSC', () => 0],
        [(name) => name === 'DSW', () => 1],
        [(name) => name === 'YSZ', () => 2],
        [(name) => name === 'QS', () => 3],
        [(name) => name === 'TH', () => 4],
        [(name) => name === 'QX', () => -1]
      ]
      this.activeName = codeLits.find((a) => a[0](menu.children[0].code))[1]()
      this.$nextTick(() => {
        this.getUserUseLog()
        this.getExpressList()
        this.height = document.querySelector('.data').offsetHeight - 43
      })
    }
  },
  methods: {
    addressTag(row) {
      let className = ''
      switch (row.classification) {
        // 0 = BUSINESS(商业), 1 = MIXED(混合), 2 = RESIDENTIAL(住宅), 3 = UNKNOWN(未知)
        case 0: className = 'address-tag-1'; break
        case 1: className = 'address-tag-4'; break
        case 2: className = 'address-tag-0'; break
        case 3: className = 'address-tag-2'; break
      }
      return className
    },
    handleClick(index) {
      this.activeName = index
      const param = {
        pageIndex: 1,
        pageSize: this.pager.pageSize
      }
      this.pager = param
      this.selectionItem = []
      this.getList(param)
      // this.initList()
    },
    handlePageChange(pager) {
      this.pager = pager
      this.getList(pager)
    },
    handleSelectionChange(items) {
      this.selectionItem = items
    },
    sortChange(orderObj) {
      if (orderObj.order) {
        this.sortable = {
          sortField: orderObj.prop,
          sortBy: orderObj.order === 'asc' ? 0 : 1
        }
      } else {
        this.sortable = {}
      }
      this.getList(this.pager)
    },
    sendFilter() {
      if (
        this.filterList.date.length > 0 ||
        this.filterList.status.length > 0 ||
        this.filterList.companyIds.length > 0 ||
        this.filterList.saleIds.length > 0 ||
        this.filterList.countryCode ||
        this.filterList.state ||
        this.filterList.accountNumber ||
        this.filterList.postalCode ||
        this.filterList.expressService ||
        this.filterList.expressSupplier
      ) {
        this.isActive = true
      } else {
        this.isActive = false
      }
      this.initList()
    },
    async getList(params) {
      this.loading = true
      this.$nextTick(async() => {
        try {
          params = {
            ...params,
            queryParam: {
              ...this.sortable,
              ...this.formatFilter()
            }
          }
          const res = await getOrderPageList(params)
          this.loading = false
          if (res.success) {
            this.tableResData.columns = res.data.list
            this.tableResData.total = res.data.total
            this.tabNumer = res.data.tabNumer
            this.tabTotal = res.data.tabTotal
          } else {
            this.$message.error(res.errorMessage)
          }
        } catch (error) {
          this.loading = false
          console.error(error)
        }
      })
    },
    getUserList() {
      getSaleList().then((res) => {
        if (res.success) {
          const arr = []
          res.data.map((item) => {
            arr.push({
              value: item.id,
              label: item.nameCH
            })
          })
          this.userOptions = arr
        } else {
          this.$message.error(res.errorMessage)
        }
      })
    },
    async getUserUseLog() {
      try {
        const res = await getSysTableUser({
          tableCode: 'order'
        })
        if (res.success) {
          if (res.data && res.data.value) {
            const filedArr = JSON.parse(res.data.value)
            this.useLogId = res.data.id
            this.useFildList = filedArr
            this.getList(this.pager)
          } else {
            this.dialogVisible = true
          }
        } else {
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        console.error(error)
      }
    },
    async getExpressList() {
      try {
        const res = await getExpressSupplierList()
        if (res.success) {
          this.supplyService = res.data
        } else {
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        console.error(error)
      }
    },
    reset() {
      this.$refs.baseMutiInput.reset()
      this.referenceNumber = ''
      this.searchVal = ''
      this.filterList = {
        date: getRecentMonthDate(1),
        // referenceNumber: '',
        status: '',
        countryCode: '',
        originCityId: '',
        state: '',
        postalCode: '',
        expressService: '',
        expressSupplier: '',
        saleIds: '',
        accountNumber: '',
        companyIds: []
      }
      this.isActive = false
    },
    customerList() {
      this.dialogVisible = true
    },
    ok() {
      this.dialogVisible = false
      this.getUserUseLog()
    },
    viewDetail(row) {
      this.editData = row
      this.drawer1 = true
    },
    formatFilter() {
      return {
        status:
          this.activeName !== 10000
            ? [this.activeName]
            : this.filterList.status?.length > 0
            ? this.filterList.status
            : undefined,
        referenceNumber: this.searchVal ? this.searchVal.split(';') : undefined,
        countryCode:
          this.filterList.countryCode?.length > 0
            ? this.filterList.countryCode
            : undefined,
        state: this.filterList.state || undefined,
        accountNumber: this.filterList.accountNumber || undefined,
        companyIds: this.filterList.companyIds || undefined,
        saleIds: this.filterList.saleIds || undefined,
        originCityId: this.filterList.originCityId || undefined,
        postalCode: this.filterList.postalCode || undefined,
        expressService: this.filterList.expressService || undefined,
        expressSupplier: this.filterList.expressSupplier || undefined,
        startTime: this.filterList.date ? this.filterList.date[0] : undefined,
        endTime: this.filterList.date ? this.filterList.date[1] : undefined
      }
    },
    ruduceRow(row) {
      this.$confirm('此操作将取消该订单, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        iconClass: 'warning',
        showClose: false,
        closeOnPressEscape: false,
        closeOnClickModal: false,
        center: true
      })
        .then(() => {
          this.loading = true
          cancel({
            orderId: row.id
          }).then((res) => {
            this.loading = false
            if (res.success) {
              this.$message.success('取消成功')
              this.initList()
            } else {
              this.$message.error(res.errorMessage)
            }
          })
        })
        .catch(() => {})
    },
    deleteRow(row) {
      this.$confirm('此操作将删除该订单, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        iconClass: 'warning',
        showClose: false,
        closeOnPressEscape: false,
        closeOnClickModal: false,
        center: true
      })
        .then(() => {
          this.loading = true
          deleteOrder({
            orderId: row.id
          }).then((res) => {
            this.loading = false
            if (res.success) {
              this.$message.success('删除成功')
              this.initList()
            } else {
              this.$message.error(res.errorMessage)
            }
          })
        })
        .catch(() => {})
    },
    initList() {
      const param = {
        pageIndex: 1,
        pageSize: 20
      }
      this.pager = param
      this.getList(param)
    },
    print(row) {
      const obj = {
        orderId: row.id,
        referenceNumber: row.referenceNumber,
        labeFormat: 'PDF'
      }
      const routeUrl = this.$router.resolve({
        path: '/pdf',
        query: { ...obj }
      })
      window.open(routeUrl.href, '_blank')
    },
    viewTrack(row) {
      this.drawer = true
      this.editData = row
    },
    viewFee(row) {
      if (row.status === 10) {
        this.$message.warning('非系统制单没数据')
        return
      }
      this.drawer2 = true
      this.editData = row
    },
    batchUpload() {
      if (this.selectionItem.length === 0) {
        this.$message.warning('请选择订单状态后在上传')
        return
      }
      let flag = true
      this.selectionItem.forEach((item) => {
        if (item.status !== 0) {
          flag = false
        }
      })
      if (!flag) {
        this.$message.warning('有部分订单状态不为待上传，请修改后重试')
        return
      }
      if (this.selectionItem.length > 10) {
        this.$message.warning('最多选择10条记录上传')
        return
      }
      this.dialogVisible1 = true
    },
    ok2() {
      this.dialogVisible1 = false
      this.initList()
    },
    async batchExport() {
      const filter = this.formatFilter()
      const date1 = dayjs(filter.endTime)
      if (date1.diff(filter.startTime, 'months') > 6) {
        this.$message.warning('最多下载6个月数据')
        return
      }
      try {
        const res = await axios({
          url: `/backEnd/api/${config.supplyCode}/Order/exportOrderList`,
          method: 'post',
          responseType: 'blob',
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json'
          },
          data: JSON.stringify(filter)
        })
          const fileName = window.decodeURI(res.headers['content-disposition'].split('=')[2], 'UTF-8').replace(`UTF-8''`, '')
          const filestream = res.data // 返回的文件流
          const blob = new Blob([filestream], {
            type: 'application/vnd.ms-excel'
          })
          const a = document.createElement('a')
          const href = window.URL.createObjectURL(blob) // 创建下载连接
          a.href = href
          a.download = fileName
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a) // 下载完移除元素
          window.URL.revokeObjectURL(href) // 释放掉blob对象
      } catch (error) {
        console.log(error)
      }
    },
    handleConfirm() {
      if (this.referenceNumber && this.referenceNumber !== '') {
        this.searchVal = this.referenceNumber.join(';')
        this.$refs.popover.doClose()
      }
      const param = {
        pageIndex: 1,
        pageSize: 20
      }
      this.pagerConfig = param
      this.getList(param)
    },
    handleEmpty() {
      this.referenceNumber = ''
      this.searchVal = ''
      this.$refs.baseMutiInput.reset()
      this.$refs.popover.doClose()
      this.initList()
    },
    handleFocus() {
      this.$refs.popover.doShow()
    },
    closeSearchPanel() {
      this.$refs.popover.doClose()
    }
  }
}
</script>

<style lang="less" scoped>
.contents {
  width: 100%;
  padding: 0 10px;
  height: 100%;
  background: #fff;
  box-sizing: border-box;
  overflow: auto;
  position: relative;
  .header {
    width: 100%;
    height: 64px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .order-status {
    width: auto;
    min-width: 56px;
    height: 24px;
    background: #35ba83;
    padding: 0 4px;
    border-radius: 4px 4px 4px 4px;
    display: inline-block;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
    box-sizing: border-box;
    cursor: pointer;
  }
  .order-status-1 {
    background: rgba(53, 186, 131, 0.1);
    color: #35ba83;
  }
  .order-status-2 {
    background: rgba(254, 69, 61, 0.1);
    color: #fe453d;
  }
  .tabItem {
    padding: 20px;
    width: 42px;
    height: 19px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    line-height: 20px;
    cursor: pointer;
  }
  .active {
    color: #2395f3;
  }
  .address {
    width: auto;
    height: 52px;
    display: flex;
    .address-content {
      width: auto;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      flex-wrap: wrap;
      margin-right: 5px;
    }
    .address-tag {
      width: auto;
      height: 24px;
      display: block;
      border-radius: 4px 4px 4px 4px;
      padding: 0 8px;
      box-sizing: border-box;
      & + .address-tag {
        margin-top: 4px;
      }
    }
    .address-detail {
      width: auto;
      height: 48px;
      font-size: 14px;
      font-weight: Regular;
      color: #666666;
      line-height: 24px;
      text-indent: 4px;
      text-align: left;
    }
    .address-tag-0 {
      background: rgba(53, 186, 131, .1);
      color: rgba(53, 186, 131, 1);
    }
    .address-tag-1 {
      background: rgba(35, 149, 243, .1);
      color: rgba(35, 149, 243, 1);
    }
    .address-tag-3 {
      background: rgba(157, 103, 254, .1);
      color: rgba(157, 103, 254, 1);
    }
    .address-tag-2 {
      background: rgba(254, 69, 61, .1);
      color: rgba(254, 69, 61, 1);
    }
    .address-tag-4 {
      background: rgba(254, 170, 79, .1);
      color: rgba(254, 170, 79, 1);
    }
  }
  .otherFee {
    background: rgba(119, 135, 158, .1);
    color: rgba(119, 135, 158, 1);
    font-size: 12px;
    height: 16px;
    line-height: 16px;
  }
  .opt {
    display: flex;
    & > div {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      cursor: pointer;
      box-sizing: border-box;
    }
    .search {
      position: relative;
      display: flex;
      width: 200px;
      margin-right: 5px;
      .searchPop {
        /deep/ .el-popover {
          z-index: 9999;
          right: 0;
          margin-top: -16px;
        }
        .close {
          position: absolute;
          top: 6px;
          right: 10px;
          cursor: pointer;
          font-size: 20px;
        }
      }
      .input-search {
        width: 166px;
        height: 30px !important;
        /deep/ .el-input__inner {
          height: 30px;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
      .btn-search {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 33px;
        height: 30px;
        border: 1px solid #dbe4f2;
        border-left: 0;
        border-radius: 0 4px 4px 0;
        cursor: pointer;
        box-sizing: border-box;
      }
    }
    .import {
      width: 82px;
      height: 30px;
      background: #2395f3;
      color: #ffffff;
      margin-right: 5px;
    }
    .customer-list {
      width: 126px;
      height: 30px;
      border: 1px solid #eeeeee;
      color: #666666;
      img {
        margin-right: 4px;
      }
    }
    .filter {
      height: 30px;
      margin-left: 8px;
    }
  }
  .data {
    width: 100%;
    height: calc(100% - 64px);
    & .fTable {
      border-top: 1px solid #ebeef5;
      box-sizing: border-box;
    }
  }
  .custom-total {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    color: #999999;
    height: 42px;
    font-weight: 400;
    font-size: 14px;
    z-index: 1000;
    .export {
      width: 84px;
      height: 32px;
      border-radius: 4px;
      border: 1px solid #eeeeee;
      box-sizing: border-box;
      cursor: pointer;
    }
    .upload {
      width: 112px;
      height: 32px;
      margin-left: 8px;
      border-radius: 4px;
      border: 1px solid #eeeeee;
      box-sizing: border-box;
      cursor: pointer;
    }
    img {
      margin-right: 4px;
    }
  }
  .status {
    width: 60px;
    height: 26px;
    border-radius: 4px 4px 4px 4px;
    font-size: 12px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    margin: 0 auto;
  }
  .status0 {
    background: rgba(53, 186, 131, .1);
    color: rgba(53, 186, 131, 1);
  }
  .status1 {
    background: rgba(254, 170, 79, .1);
    color: rgba(254, 170, 79, 1);
  }
  .status2 {
    background: rgba(255, 116, 68, .1);
    color: rgba(255, 116, 68, 1);
  }
  .status3 {
    background: rgba(35, 149, 243, .1);
    color: rgba(35, 149, 243, 1);
  }
  .status-1 {
    background: rgba(153, 153, 153, .1);
    color: rgba(153, 153, 153, 1);
  }
  .table-opt {
    display: flex;
    justify-content: space-around;
  }
}
</style>
