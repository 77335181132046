var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Drawer",
    {
      attrs: { drawer: _vm.drawer, title: "订单详情", size: 1160 },
      on: { close: _vm.close },
    },
    [
      _c("div", { staticClass: "view" }, [
        _c(
          "div",
          { staticClass: "panle-container" },
          [
            _c("div", { staticClass: "panle-title" }, [_vm._v("基本信息")]),
            _c(
              "el-row",
              [
                _c("el-col", { attrs: { span: 8 } }, [
                  _c("div", { staticClass: "field" }, [
                    _c("label", [_vm._v("订单编号")]),
                    _c("p", [_vm._v(_vm._s(_vm.order.id))]),
                  ]),
                ]),
                _c("el-col", { attrs: { span: 8 } }, [
                  _c("div", { staticClass: "field" }, [
                    _c("label", [_vm._v("客户单号")]),
                    _c(
                      "p",
                      {
                        staticClass: "orderNo",
                        staticStyle: { color: "#2395f3" },
                      },
                      [
                        _c("span", { staticClass: "copy" }, [
                          _vm._v(_vm._s(_vm.order.referenceNumber)),
                        ]),
                        _c("img", {
                          attrs: {
                            src: require("@/assets/images/order/copy2.png"),
                            title: "复制",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.handleCopy(_vm.order.referenceNumber)
                            },
                          },
                        }),
                      ]
                    ),
                  ]),
                ]),
                _c("el-col", { attrs: { span: 8 } }, [
                  _c("div", { staticClass: "field" }, [
                    _c("label", [_vm._v("追踪单号")]),
                    _c(
                      "p",
                      {
                        staticClass: "orderNo",
                        staticStyle: { color: "#2395f3" },
                      },
                      [
                        _c("span", { staticClass: "copy" }, [
                          _vm._v(_vm._s(_vm.order.trackingNumber)),
                        ]),
                        _c("img", {
                          attrs: {
                            src: require("@/assets/images/order/copy2.png"),
                            title: "复制",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.handleCopy(_vm.order.trackingNumber)
                            },
                          },
                        }),
                      ]
                    ),
                  ]),
                ]),
              ],
              1
            ),
            _c(
              "el-row",
              [
                _c("el-col", { attrs: { span: 8 } }, [
                  _c("div", { staticClass: "field" }, [
                    _c("label", [_vm._v("件数")]),
                    _c("p", [_vm._v(_vm._s(_vm.order.qty))]),
                  ]),
                ]),
                _c("el-col", { attrs: { span: 8 } }, [
                  _c("div", { staticClass: "field" }, [
                    _c("label", [_vm._v("状态")]),
                    _c("p", { staticClass: "status" }, [
                      _vm._v(_vm._s(_vm._f("formatStatus")(_vm.order.status))),
                    ]),
                  ]),
                ]),
                _c("el-col", { attrs: { span: 8 } }, [
                  _c("div", { staticClass: "field" }, [
                    _c("label", [_vm._v("实重")]),
                    _c("p", [_vm._v(_vm._s(_vm.order.actWeightKG) + " KG")]),
                  ]),
                ]),
              ],
              1
            ),
            _c(
              "el-row",
              [
                _c("el-col", { attrs: { span: 8 } }, [
                  _c("div", { staticClass: "field" }, [
                    _c("label", [_vm._v("材积重")]),
                    _c("p", [_vm._v(_vm._s(_vm.order.volumeWeightKG) + " KG")]),
                  ]),
                ]),
                _c("el-col", { attrs: { span: 8 } }, [
                  _c("div", { staticClass: "field" }, [
                    _c("label", [_vm._v("计费重")]),
                    _c("p", [_vm._v(_vm._s(_vm.order.weightKG) + " KG")]),
                  ]),
                ]),
                _c("el-col", { attrs: { span: 8 } }, [
                  _c("div", { staticClass: "field" }, [
                    _c("label", [_vm._v("费用")]),
                    _c("p", [
                      _vm._v(
                        _vm._s(_vm.order.estimateAmount) +
                          " " +
                          _vm._s(_vm.order.currency)
                      ),
                    ]),
                  ]),
                ]),
              ],
              1
            ),
            _c(
              "el-row",
              [
                _c("el-col", { attrs: { span: 8 } }, [
                  _c("div", { staticClass: "field" }, [
                    _c("label", [_vm._v("国家")]),
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          _vm.formatterCountryCode(_vm.order.originCountryCode)
                        )
                      ),
                    ]),
                  ]),
                ]),
                _c("el-col", { attrs: { span: 8 } }, [
                  _c("div", { staticClass: "field" }, [
                    _c("label", [_vm._v("派送方式")]),
                    _c(
                      "p",
                      {
                        staticStyle: {
                          color: "#2395f3",
                          "font-weight": "bold",
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.order.expressSupplier) +
                            " " +
                            _vm._s(_vm.order.expressService)
                        ),
                      ]
                    ),
                  ]),
                ]),
                _c("el-col", { attrs: { span: 8 } }, [
                  _c("div", { staticClass: "field" }, [
                    _c("label", [_vm._v("创建时间")]),
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          _vm.order.createdOn
                            ? _vm.order.createdOn.slice(
                                0,
                                _vm.order.createdOn.length - 3
                              )
                            : ""
                        )
                      ),
                    ]),
                  ]),
                ]),
              ],
              1
            ),
            _c(
              "el-row",
              [
                _c("el-col", { attrs: { span: 8 } }, [
                  _c("div", { staticClass: "field" }, [
                    _c("label", [_vm._v("上传时间")]),
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          _vm.order.uploadOn
                            ? _vm.order.uploadOn.slice(
                                0,
                                _vm.order.uploadOn.length - 3
                              )
                            : ""
                        )
                      ),
                    ]),
                  ]),
                ]),
                _vm.order.fbaId || _vm.order.fbaRefNo
                  ? _c("el-col", { attrs: { span: 8 } }, [
                      _c("div", { staticClass: "field" }, [
                        _c("label", [_vm._v("FBA信息")]),
                        _c("p", [
                          _vm.order.fbaId
                            ? _c("span", [_vm._v(_vm._s(_vm.order.fbaId))])
                            : _vm._e(),
                          _vm.order.fbaId && _vm.order.fbaRefNo
                            ? _c(
                                "span",
                                { staticStyle: { margin: "0 10px" } },
                                [_vm._v("|")]
                              )
                            : _vm._e(),
                          _vm.order.fbaRefNo
                            ? _c("span", [_vm._v(_vm._s(_vm.order.fbaRefNo))])
                            : _vm._e(),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
                _c("el-col", { attrs: { span: 8 } }, [
                  _c("div", { staticClass: "field" }, [
                    _c("label", [_vm._v("备注")]),
                    _c(
                      "p",
                      [
                        _c(
                          "el-popover",
                          {
                            attrs: {
                              placement: "bottom-end",
                              width: "440",
                              trigger: "hover",
                            },
                          },
                          [
                            _c("div", { staticClass: "remarks-box" }, [
                              _c("div", { staticClass: "remarks-header" }, [
                                _c("span", [_vm._v("备注内容")]),
                                _c(
                                  "span",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleCopy(_vm.order.remarks)
                                      },
                                    },
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: require("@/assets/images/order/copy3.png"),
                                      },
                                    }),
                                    _vm._v("复制"),
                                  ]
                                ),
                              ]),
                              _c("div", { staticClass: "remarks-body" }, [
                                _vm._v(" " + _vm._s(_vm.order.remarks) + " "),
                              ]),
                            ]),
                            _c("img", {
                              attrs: {
                                slot: "reference",
                                src: require("@/assets/images/const/message.png"),
                              },
                              slot: "reference",
                            }),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ],
              1
            ),
            _vm.order.deliveryDemand
              ? _c("el-row", [
                  _c("div", { staticClass: "field" }, [
                    _c("label", [_vm._v("送仓要求")]),
                    _c("p", [_vm._v(_vm._s(_vm.order.deliveryDemand))]),
                  ]),
                ])
              : _vm._e(),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "panle-container" },
          [
            _c(
              "div",
              {
                staticClass: "type",
                style: { width: _vm.quotesType === 1 ? "240px" : "120px" },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "btn",
                    class: { active: _vm.addressActive === 0 },
                    style: { width: _vm.quotesType === 1 ? "50%" : "100%" },
                    on: {
                      click: function ($event) {
                        _vm.addressActive = 0
                      },
                    },
                  },
                  [_vm._v(" 收件方信息 ")]
                ),
                _vm.quotesType === 1
                  ? _c(
                      "div",
                      {
                        staticClass: "btn",
                        class: { active: _vm.addressActive === 1 },
                        on: {
                          click: function ($event) {
                            _vm.addressActive = 1
                          },
                        },
                      },
                      [_vm._v(" 进口商信息 ")]
                    )
                  : _vm._e(),
              ]
            ),
            _c(
              "el-row",
              [
                _c("el-col", { attrs: { span: 12 } }, [
                  _c("div", { staticClass: "field" }, [
                    _c("label", [_vm._v("公司名称")]),
                    _c("p", [_vm._v(_vm._s(_vm.address.companyName))]),
                  ]),
                ]),
                _c("el-col", { attrs: { span: 12 } }, [
                  _c("div", { staticClass: "field" }, [
                    _c("label", [_vm._v("收件人")]),
                    _c("p", [_vm._v(_vm._s(_vm.address.attentionName))]),
                  ]),
                ]),
              ],
              1
            ),
            _c(
              "el-row",
              [
                _vm.quotesType && _vm.addressActive
                  ? _c("el-col", { attrs: { span: 6 } }, [
                      _c("div", { staticClass: "field" }, [
                        _c("label", [_vm._v("税号")]),
                        _c("p", [_vm._v(_vm._s(_vm.address.taxNumber))]),
                      ]),
                    ])
                  : _vm._e(),
                _c("el-col", { attrs: { span: 6 } }, [
                  _c("div", { staticClass: "field" }, [
                    _c("label", [_vm._v("国家")]),
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          _vm.formatterCountryCode(_vm.address.countryCode)
                        )
                      ),
                    ]),
                  ]),
                ]),
                _c("el-col", { attrs: { span: 6 } }, [
                  _c("div", { staticClass: "field" }, [
                    _c("label", [_vm._v("省州")]),
                    _c("p", [_vm._v(_vm._s(_vm.address.state))]),
                  ]),
                ]),
                _c("el-col", { attrs: { span: 6 } }, [
                  _c("div", { staticClass: "field" }, [
                    _c("label", [_vm._v("城市")]),
                    _c("p", [_vm._v(_vm._s(_vm.address.city))]),
                  ]),
                ]),
                !_vm.addressActive
                  ? _c("el-col", { attrs: { span: 6 } }, [
                      _c("div", { staticClass: "field" }, [
                        _c("label", [_vm._v("邮编")]),
                        _c("p", [_vm._v(_vm._s(_vm.address.postalCode))]),
                      ]),
                    ])
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "el-row",
              [
                _vm.addressActive
                  ? _c("el-col", { attrs: { span: 6 } }, [
                      _c("div", { staticClass: "field" }, [
                        _c("label", [_vm._v("邮编")]),
                        _c("p", [_vm._v(_vm._s(_vm.address.postalCode))]),
                      ]),
                    ])
                  : _vm._e(),
                _c("el-col", { attrs: { span: 6 } }, [
                  _c("div", { staticClass: "field" }, [
                    _c("label", [_vm._v("电话")]),
                    _c("p", [_vm._v(_vm._s(_vm.address.phone))]),
                  ]),
                ]),
                _c("el-col", { attrs: { span: 6 } }, [
                  _c("div", { staticClass: "field" }, [
                    _c("label", [_vm._v("邮箱")]),
                    _c("p", [_vm._v(_vm._s(_vm.address.email))]),
                  ]),
                ]),
              ],
              1
            ),
            _c(
              "el-row",
              [
                _c("el-col", { attrs: { span: 24 } }, [
                  _c("div", { staticClass: "field" }, [
                    _c("label", [_vm._v("详细地址一")]),
                    _c("p", [
                      _c("span", { staticClass: "label flex_c_c" }, [
                        _vm._v(_vm._s(_vm.address.classificationName)),
                      ]),
                      _vm._v(" " + _vm._s(_vm.address.addressLine1) + " "),
                    ]),
                  ]),
                ]),
              ],
              1
            ),
            _c(
              "el-row",
              [
                _c("el-col", { attrs: { span: 24 } }, [
                  _c("div", { staticClass: "field" }, [
                    _c("label", [_vm._v("详细地址二")]),
                    _c("p", [_vm._v(_vm._s(_vm.address.addressLine2))]),
                  ]),
                ]),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "type",
            staticStyle: { "margin-left": "35px" },
            style: { width: _vm.quotesType === 1 ? "240px" : "120px" },
          },
          [
            _c(
              "div",
              {
                staticClass: "btn",
                class: { active: _vm.active === 0 },
                style: { width: _vm.quotesType === 1 ? "50%" : "100%" },
                on: {
                  click: function ($event) {
                    return _vm.changeType(0)
                  },
                },
              },
              [_vm._v(" 包裹信息 ")]
            ),
            _vm.quotesType === 1
              ? _c(
                  "div",
                  {
                    staticClass: "btn",
                    class: { active: _vm.active === 1 },
                    on: {
                      click: function ($event) {
                        return _vm.changeType(1)
                      },
                    },
                  },
                  [_vm._v(" 产品信息 ")]
                )
              : _vm._e(),
          ]
        ),
        _c(
          "div",
          { staticClass: "data" },
          [
            _c("BaseTable", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.active,
                  expression: "active",
                },
              ],
              attrs: {
                height: "320",
                tableData: _vm.tableData2,
                pageIndex: _vm.pager.pageIndex,
                pageSize: _vm.pager.pageSize,
                loading: _vm.loading,
                isPager: true,
              },
              on: { handlePageChange: _vm.handlePageChange },
            }),
            _c("BaseTable", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.active,
                  expression: "!active",
                },
              ],
              attrs: {
                height: "320",
                tableData: _vm.tableData1,
                pageIndex: _vm.pager.pageIndex,
                pageSize: _vm.pager.pageSize,
                loading: _vm.loading,
                isPager: true,
              },
              on: {
                handlePageChange: _vm.handlePageChange,
                sortChange: _vm.sortChange,
              },
              scopedSlots: _vm._u([
                {
                  key: "productURL",
                  fn: function (row) {
                    return [
                      _c("i", {
                        staticClass: "el-icon-link",
                        on: {
                          click: function ($event) {
                            return _vm.openUrl(row.scope.productURL)
                          },
                        },
                      }),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }