<template>
  <el-dialog
    v-drag
    :visible.sync="dialogVisible"
    width="1024px"
    title="批量上传"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="cancel"
  >
    <BaseTable v-loading="loadingReq" height="440" :tableData="tableData" :isPager="false" :border="true">
      <template slot="sendType" slot-scope="row">
        <div class="send-type">
          <img
            :src="require(`@/assets/images/order/${row.scope.express.toLowerCase()}.png`)"
          />
          <div class="des">
            <div class="filed">
              <label>服务类型</label>
              <span style="color: #2395f3">{{
                defalutSendArr[row.tIndex].service
              }}</span>
            </div>
            <div class="filed">
              <label>预估费用</label>
              <span
                style="color: #fe453d"
              >{{ defalutSendArr[row.tIndex].estimateAmount }}
                {{ defalutSendArr[row.tIndex].currency }}</span
              >
            </div>
            <div class="filed">
              <label>RMB费用</label>
              <span
                style="color: #fe453d"
              >{{ defalutSendArr[row.tIndex].estimateAmountRMB }} RMB</span
              >
            </div>
            <div class="filed">
              <label>RMB费用/KG</label>
              <span
                style="color: #fe453d"
              >{{
                defalutSendArr[row.tIndex].perKgEstimateAmountRMB
              }}
                RMB</span
              >
            </div>
          </div>
          <el-popover placement="left-end" width="450" trigger="hover">
            <i
              slot="reference"
              class="el-icon-arrow-down"
              style="margin: 26px 20px; cursor: pointer"
            />
            <div class="send-list">
              <div
                v-for="(item, $index) in row.scope.quoteResult"
                :key="item.quoteId"
                class="panel"
                :class="{
                  select: item.quoteId === defalutSendArr[row.tIndex].quoteId,
                }"
              >
                <img
                  :src="require(`@/assets/images/order/${row.scope.express.toLowerCase()}.png`)"
                />
                <div class="des1">
                  <div class="filed">
                    <label>服务类型</label>
                    <span style="color: #2395f3">{{ item.service }}</span>
                  </div>
                  <div class="filed">
                    <label>预估费用</label>
                    <span
                      style="color: #fe453d"
                    >{{ item.estimateAmount }} {{ item.currency }}</span
                    >
                  </div>
                </div>
                <div class="des2">
                  <div class="filed">
                    <label>RMB费用</label>
                    <span style="color: #fe453d">{{
                      item.estimateAmountRMB
                    }}</span>
                  </div>
                  <div class="filed">
                    <label>RMB费用/KG</label>
                    <span style="color: #fe453d">{{
                      item.perKgEstimateAmountRMB
                    }}</span>
                  </div>
                </div>
                <div class="checkbox">
                  <el-checkbox
                    v-model="item.isDefault"
                    @change="changeDefalutSend(row.tIndex, item, $index)"
                  ></el-checkbox>
                </div>
              </div>
            </div>
          </el-popover>
        </div>
      </template>
      <template slot="status" slot-scope="row">
        <span
          v-if="uploadArr[row.tIndex] === 0"
          class="wait-upload"
        >待上传</span
        >
        <i
          v-else-if="uploadArr[row.tIndex] === 2"
          class="el-icon-circle-check icon"
          style="color: #33c481"
        />
        <i
          v-else-if="uploadArr[row.tIndex] === 3"
          class="el-icon-circle-close icon"
          style="color: #f94848"
        />
        <div
          v-else
          v-loading="uploadArr[row.tIndex] === 1"
          element-loading-spinner="el-icon-loading"
          class="loading"
        ></div>
      </template>
    </BaseTable>
    <span slot="footer" class="footers">
      <el-button
        :disabled="disabled"
        class="cancel"
        @click="cancel"
      >取消</el-button
      >
      <el-button :disabled="disabled" class="ok" @click="ok">{{
        okText
      }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
const BaseTable = () => import('@/components/table/BaseTable')
import { batchUploadData, shipment } from '@/api/fee'
export default {
  components: { BaseTable },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    selectionItem: {
      type: Array,
      default: function() {
        return []
      }
    }
  },
  data() {
    return {
      orderList: [],
      disabled: false,
      isUploadComplete: false,
      loadingReq: true,
      okText: '确定上传',
      tableData: {
        fileds: [
          {
            filed: 'referenceNumber',
            label: '客户单号',
            width: '140',
            resizable: true
          },
          {
            filed: 'qty',
            label: '件数',
            width: '80',
            resizable: true
          },
          {
            filed: 'realWeight',
            label: '实重',
            width: '100',
            resizable: true
          },
          {
            filed: 'volumeWeigth',
            label: '材积重',
            width: '100',
            resizable: true
          },
          {
            filed: 'calculateWeight',
            label: '计费重',
            width: '100',
            resizable: true
          },
          {
            filed: 'sendType',
            label: '选择派送方式',
            width: '',
            isCustom: true,
            resizable: true
          },
          {
            filed: 'status',
            label: '状态',
            width: '99',
            isCustom: true,
            resizable: true
          }
        ],
        columns: [],
        total: 0
      },
      uploadArr: [] // 0 待上传 1 上传中 2上传完成 3上传失败
    }
  },
  computed: {
    defalutSendArr: function() {
      const arr = []
      this.tableData.columns.forEach((item) => {
        item.quoteResult.forEach((a) => {
          if (a.isDefault) {
            arr.push(a)
          }
        })
      })
      return arr
    }
  },
  watch: {
    dialogVisible(val) {
      if (val) {
        this.okText = '确定上传'
        this.loadingReq = true
        this.isUploadComplete = false
        this.getData()
      }
    }
  },
  methods: {
    async getData() {
      let str = ''
      this.selectionItem.forEach((item, index) => {
        if (index === this.selectionItem.length - 1) {
          str += `${item.id}`
        } else {
          str += `${item.id},`
        }
      })
      try {
        const res = await batchUploadData({
          orderId: str
        })
        const orderArr = []
        const uploadArr = []
        if (res.success) {
          this.loadingReq = false
          res.data && res.data.forEach((item) => {
            const quoteResultArr = []
            uploadArr.push(0)
            item.quoteResult.forEach((a) => {
              if (a.isVisible) {
                quoteResultArr.push(a)
              }
            })
            orderArr.push({
              ...item,
              quoteResult: quoteResultArr
            })
          })
          this.uploadArr = uploadArr
          this.tableData.columns = orderArr
        } else {
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        console.error(error)
      }
    },
    cancel() {
      this.$emit('cancel')
    },
    changeDefalutSend(index, item, defalutIndex) {
      const quoteResult = this.tableData.columns[index].quoteResult.map(
        (item, m) => {
          if (m === defalutIndex) {
            return {
              ...item,
              isDefault: true
            }
          } else {
            return {
              ...item,
              isDefault: false
            }
          }
        }
      )
      const obj = {
        ...this.tableData.columns[index]
      }
      obj.quoteResult = quoteResult
      this.tableData.columns.splice(index, 1, obj)
    },
    async ok() {
      if (this.isUploadComplete) {
        this.$emit('ok')
        return
      }
      this.disabled = true
      this.okText = '请稍等'
      const promise = this.tableData.columns.map(async(item, index) => {
        const params = {
          referenceNumber: '',
          masterOrderId: item.orderId,
          quoteId: this.defalutSendArr[index].quoteId
        }
        let res
        try {
          res = await shipment(params)
        } catch (error) {
          console.log(error)
          this.disabled = false
          this.okText = '完成'
          this.isUploadComplete = true
        }
        return new Promise((resolve) => {
          resolve(res)
        })
      })
      Promise.all(promise).then(res => {
        console.log(res)
        let str = ''
        res.forEach((a, index) => {
            if (a.success) {
                this.uploadArr.splice(index, 1, 2)
            } else {
                this.uploadArr.splice(index, 1, 3)
                str += a.errorMessage + ';'
            }
        })
        if (str) {
          this.$message.error(str)
        }
        this.disabled = false
        this.okText = '完成'
        this.isUploadComplete = true
      })
    }
  }
}
</script>

<style lang="less" scoped>
.footers {
  width: 100%;
  display: flex;
  justify-content: center;
  .cancel {
    width: 120px;
    height: 40px;
    background: #EEEEEE;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    margin-right: 10px;
    cursor: pointer;
    border: 0;
  }
  .ok {
    width: 120px;
    height: 40px;
    background: #2395f3;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    cursor: pointer;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
  }
}
.send-type {
  display: flex;
  justify-content: space-between;
  .filed {
    font-size: 12px;
    line-height: 16px;
    display: flex;
    justify-content: space-between;
    label {
      width: 73px;
      text-align: right;
      display: inline-block;
    }
  }
  .des {
    width: 162px;
  }
}
.send-list {
  width: 100%;
  max-height: 240px;
  background: #ffffff;
  position: relative;
  overflow-y: auto;
  .panel {
    width: 100%;
    height: 80px;
    border-radius: 4px;
    display: flex;
  }
  .select {
    background: #f8fbff;
  }
  img {
    margin: 10px 15px 10px 5px;
  }
  .des1 {
    width: 148px;
    font-size: 12px;
    line-height: 16px;
    padding: 22px 0;
    span {
      margin-left: 10px;
    }
  }
  .des2 {
    width: 170px;
    font-size: 12px;
    line-height: 16px;
    padding: 22px 0;
    label {
      width: 73px;
    }
    span {
      margin-left: 10px;
    }
  }
  .checkbox {
    position: absolute;
    right: 10px;
    padding-top: 32px;
  }
}
.loading {
  height: 35px;
}
.wait-upload {
  font-size: 12px;
  font-weight: 400;
  color: #CDCDCD;
  line-height: 24px;
}
.icon {
  font-size: 16px;
}
</style>
